import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ResponsePagination } from 'src/app/models/response';
import { LegalPosition } from 'src/app/models/legal-position';

@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.scss']
})
export class SearchListComponent {

  constructor() { }
  @Input() searchResponse: ResponsePagination<LegalPosition>;
  @Input() search;
  @Input() label = 'Нещодавно додані правові позиції';
  @Input() typeData = 'legal_positions';
  @Input() customClass;
  @Output() SetPerPage = new EventEmitter<number>();
  @Output() ToPage = new EventEmitter<number>();

  setPerPage(data) {
    this.SetPerPage.emit(data);
  }
  toPage(data) {
    this.ToPage.emit(data);
  }
}
