import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-approve-modal',
  templateUrl: './approve-modal.component.html',
  styleUrls: ['./approve-modal.component.scss']
})
export class ApproveModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ApproveModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,) { }
  title = 'Ви дійсно бажаєте вийти?';
  description = 'У разі виходу, всі заповнені поля будуть очищено і додавання рішення потрібно буде розпочинати спочатку.';
  canselBtnTxt = 'Скасувати';
  saveBtnTxt = 'Так, вийти';
  ngOnInit(): void {
    Object.assign(this, this.data);
  }

}
