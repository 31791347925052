<div class="default-modal-template-block">
  <div
    class="background-toggle"
    (click)="dialogRef.close()"
  ></div>
  <div class="background-blur" (click)="dialogRef.close()"></div>
  <div class="default-modal-template legal-position-modal">
    <button
      class="close-modal"
      (click)="dialogRef.close()"
    >
      <i class="icon-cross"></i>
    </button>
    <div class="top-container">
      <div class="image-container">
        <img src="../../../assets/images/document.svg" alt="" />
      </div>
      <div class="text-container">
        <h2>
          <span *ngIf="data?.legalPosition;">Редагувати правову позицію</span>
          <span *ngIf="!data?.legalPosition;">Завантажити правову позицію</span>
        </h2>
        <p>
          <span *ngIf="data?.legalPosition;">Скористайтеся полями нижче для того, щоб змінити правову позицію.</span>
          <span *ngIf="!data?.legalPosition;">Скористайтеся полями нижче для додавання нової правової позиції до системи.</span>
          

        </p>
      </div>
    </div>
    <app-separator [legalPosition]="true" [bgW]="true"></app-separator>
    <div class="form-container" [formGroup]="legalPossForm">
      <div class="input-container"
      [ngClass]="{
        error: (f('title').invalid && (f('title').dirty || f('title').touched)),
        'placeholder-disabled': f('title').value}">
        <div class="input-title">
          <label>Назва</label>
          <span>Обов’язково</span>
        </div>
        <div class="input-wrapper">
          <input type="text" formControlName="title">
          <div class="placeholder">Введіть назву правової позиції</div>
        </div>
        <p class="error-text"></p>
      </div>
      <div class="input-container textarea-flex"
      [ngClass]="{
        error: (f('text').invalid && (f('text').dirty && f('text').touched)),
        'placeholder-disabled': f('text').value}">
        <div class="input-title">
          <label class="have-icon"><i class="icon-text"></i> Текст правової позиції</label>
          <span>Обов’язково</span>
        </div>
        <div class="input-wrapper">
          <textarea formControlName="text"></textarea>
          <div class="placeholder">Введіть текст правової позиції</div>
        </div>
        <p class="error-text"></p>
      </div>
      <div class="attach-legal-categories">
        <i class="icon-clasificator"></i>
        <span (click)="attachCategories()">{{selectedCategories?.length ? 'Змінити' : 'Обрати'}} категорії правової позиції</span>
        <div class="list-selected-categories" *ngIf="selectedCategories?.length">
          <div class="list-item" *ngFor="let item of selectedCategories">
            {{item?.title}}
          </div>
        </div>
      </div>
    </div>
    <div class="buttons-container">
      <button class="button-cancel" (click)="dialogRef.close()">
        Скасувати
      </button>

        <button class="button-send" (click)="onSubmit()">
          <span *ngIf="data?.legalPosition && !checkedSimilarityLegal">Редагувати</span>
          <span *ngIf="!data?.legalPosition && !checkedSimilarityLegal">Завантажити</span>
          <span *ngIf="checkedSimilarityLegal" class="check-similarity">
            <i class="icon-loader"></i>
            Перевіряємо на схожість…
          </span>

        </button>

<!-- 
      {type === "suggest" && (
        <button class="button-send" disabled={loading}>
          Запропонувати зміну
        </button>
      )} -->
    </div>
  </div>
</div>
