<div class="approve-modal-container">
  <div class="close-btn" (click)="dialogRef.close()">
    <i class="icon-cross-1"></i>
  </div>
  <div class="inner">
    <div class="title">
      <span>{{title}}</span>
    </div>
    <div class="description">
      <span>
        {{description}}
      </span>
    </div>
  </div>
  <div class="buttons-container">
    <div class="cansel-btn" (click)="dialogRef.close()">
      <span>
        {{canselBtnTxt}}
      </span>
    </div>
    <div class="save-btn" (click)="dialogRef.close(true)">
      <span>
        {{saveBtnTxt}}
      </span>
    </div>
  </div>
</div>
