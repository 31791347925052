<div class="default-modal-template-block center">
  <div
    class="background-toggle"
    (click)="dialogRef.close()"
  ></div>
  <div class="background-blur" (click)="dialogRef.close()"></div>
  <div class="default-modal-template warning-modal">
    <button
      class="close-modal"
      (click)="dialogRef.close()"
    >
      <i class="icon-cross-new"></i>
    </button>
    <div class="top-container">
      <div class="image-container">
        <img src="../../../assets/images/warning.svg" alt="" />
      </div>
      <div class="text-container">
        <h2>
          <span>Застереження</span>
        </h2>
        <p>
          <span>Застереження щодо Бази правових позицій</span>
        </p>
      </div>
    </div>
    <app-separator [legalPosition]="true" [bgW]="true" [customPadding]='35'></app-separator>

    <div class="list-warning">
      <ol>
        <li>
          <span>
            База правових позицій Верховного Суду є тестовим допоміжним інструментом пошуку практики Верховного
            Суду та не може вважатись офіційним джерелом інформації щодо судових рішень Верховного Суду.
          </span>
        </li>
        <li>
          <span>
            Заголовки й опис рішень Верховного Суду, які містяться в Базі, коротко окреслюють правову проблему,
            якої стосується відповідне рішення. Для розуміння суті правової позиції, сформульованої у справі,
            необхідно ознайомитися з повним текстом судового рішення.
          </span>
        </li>
        <li>
          <span>
            Розміщена в Базі інформація щодо практики Верховного Суду не є вичерпною і постійно оновлюється.
          </span>
        </li>
        <li>
          <span>
            Інформація в Базі не є зобов’язуючою для Верховного Суду.
          </span>
        </li>
      </ol>
    </div>

  </div>
</div>
