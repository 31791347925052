<div class="list-item">
  <a class="list-inner" routerLink="/adjudication/{{data?.id}}">
    <div class="icon-container">
      <img src="../../../../assets/images/decision-list-item.svg" alt="" />
    </div>
    <div class="content-container">
      <span class="link-item">
        <h5>{{data?.title}}</h5>
      </span>
    </div>
    <div class="date-container">
      від {{data?.adjudicationDate | date : 'dd.MM.yyyy'}}
    </div>
  </a>
</div>
