<app-header [isAuth]="authService.isAuthenticated()" [sidebarVisible]="sidebarVisible" [currentUser]="currentUser | async" (ToggleSidebar)="toggleSidebar()" #header></app-header>
<div class="private-page" [ngClass]="{
  'public-page': !checkAuthPage(),
  'private-page': checkAuthPage(),
  'footer-disabled': checkActiveLink()
  }">
  <app-sidebar [isAuth]="authService.isAuthenticated()" [sidebarVisible]="sidebarVisible" (ToggleSidebar)="toggleSidebar()"></app-sidebar>
  <div class="wrapper">
    <div class="routes-container">
      <router-outlet></router-outlet>
    </div>
    <div class="name-container" *ngIf="!checkAuthPage()">
      <i class="icon-trident"></i>
      <hr />
      <h1>Верховний Суд</h1>
      <p>База правових позицій</p>
    </div>
  </div>
</div>
<app-footer *ngIf="!checkActiveLink()" [isAuth]="authService.isAuthenticated()"></app-footer>
<app-notification-local></app-notification-local>
