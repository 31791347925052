import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-separator',
  templateUrl: './separator.component.html',
  styleUrls: ['./separator.component.scss']
})
export class SeparatorComponent implements OnInit {

  constructor() { }
  @Input() legalPosition;
  @Input() bgW;
  @Input() fullW;
  @Input() customPadding;
  ngOnInit(): void {
  }

}
