import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../helpers/destroyable.component';
import { IDocumentsFilters } from '../../../models/documents';
import { CourtDecisionService } from '../../new-court-decisions/court-decisions.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends Destroyable implements OnInit {

  constructor(private courtDecisionService: CourtDecisionService,) {
    super();
  }
  @Input() sidebarVisible = true;
  @Input() isAuth = false;
  @Output() ToggleSidebar = new EventEmitter();

  routerList = [
    {
      icon: 'home',
      name: 'Головна сторінка',
      path: '/',
    },
    {
      icon: 'write',
      name: 'Додати судове рішення',
      path: '/adjudication',
      private: true,
    },
    {
      icon: 'new-court',
      name: 'Нові судові рішення',
      path: '/new-court-decisions/new',
      count: 0,
      private: true,
    },
    {
      icon: 'yespl',
      name: 'Рішення ЄСПЛ',
      path: '/decision-yespl/all'
    },
    {
      icon: 'new-digest',
      name: 'Дайджести',
      path: '/digest',
    },
    // {
    //   icon: "bookmark",
    //   name: "Збережені правові позиції",
    //   path: "/",
    // },
    // {
    //   // TODO delete
    //   icon: 'bookmark',
    //   name: 'test',
    //   path: '/test',
    // },
  ];
  ngOnInit(): void {
    const filters: IDocumentsFilters = {
      'exists[legalPositions]': 'false',
    }
    this.courtDecisionService.get(1, 1, filters).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
      this.routerList[2].count = resp?.pagination?.totalItems || 0;
    });
  }
  toggleSideber() {
    this.ToggleSidebar.emit();
  }
}
