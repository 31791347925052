import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Helpers } from 'src/app/helpers/helpers';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'src/app/modules/common-components/notification-local/notifications.service';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private notiService: NotificationsService) {
    this.route.params.subscribe(res => {
      this.hash = res?.id;
      if(!this.hash) {
        return router.navigateByUrl('/');
      }
    });
  }
  showPassword;
  showPasswordRepeat;
  hash;
  createPasswordForm = new FormGroup({
    password: new FormControl('', [Validators.required]),
    passwordRepeat: new FormControl('', [Validators.required])
  });
  ngOnInit(): void {
  }

  f(control) { return this.createPasswordForm.get(control); }

  validReapetPassword = true;
  errorChangePass = false;

  checkReapetPass(e) {
    const passNode = e.target;
    setTimeout(() => {
      this.validReapetPassword = passNode.value !== this.f('password').value;
    });
  }

  onSubmit() {
    if(this.createPasswordForm.valid && this.f('password').value === this.f('passwordRepeat').value) {
      this.authService.changePassword({hash: this.hash, password: this.f('password').value}).subscribe(res => {
        if (res.status === 401) {
          this.errorChangePass = true;
        } else {
          this.router.navigateByUrl('/');
        }
      });
    }
  }
}
