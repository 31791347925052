import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { IResponseAuth } from 'src/app/models/response';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { IUser } from 'src/app/models/user';

@Injectable()
export class AuthService {



  constructor(private http: HttpClient, private router: Router) {
    this.jwtHelper = new JwtHelperService();
  }

  get currentUser$(){
    return this.currentUser.asObservable();
  }
  private BASE_URL = `${environment.searchUrl}`;
  private jwtHelper: JwtHelperService;

  public currentUser: Subject<IUser> = new BehaviorSubject<IUser>(null);
  login(data): Observable<IResponseAuth> {
    return this.http.post<IResponseAuth>(`${this.BASE_URL}/jwt/auth`, data).pipe(map(response => {
      this.successLogin(response)
      return response;
    }),
      catchError(error => {
        return new BehaviorSubject<any>(error).asObservable();
      }));
  }

  successLogin(response) {
    localStorage.setItem('auth', JSON.stringify(response));
    this.getCurrentUser().subscribe(res => {
      this.addCurrentUser(res);
    });
  }
  logout() {
    localStorage.clear();
    this.router.navigateByUrl('/login');
    window.location.reload();
  }

  restore(data): Observable<IResponseAuth> {
    return this.http.post<IResponseAuth>(`${this.BASE_URL}/registration/password/reset`, data).pipe(map(response => {
      return response;
    }),
      catchError(error => {
        return new BehaviorSubject<any>(error.error).asObservable();
      }));
  }

  changePassword(data) {
    return this.http.patch<IResponseAuth>(`${this.BASE_URL}/registration/password/change`, data).pipe(map(response => {
      this.successLogin(response['data'])
      return response;
    }),
      catchError(error => {
        return new BehaviorSubject<any>(error.error).asObservable();
      }));

  }

  register(data): Observable<IResponseAuth> {
    return this.http.post<IResponseAuth>(`${this.BASE_URL}/registration`, data).pipe(map(response => {
      return response;
    }),
    catchError(error => {
      return new BehaviorSubject<any>(error).asObservable();
    }));
  }




  getAuthToken() {
    return JSON.parse(localStorage.getItem('auth')) ? JSON.parse(localStorage.getItem('auth')).token : '';
  }
  getRefreshToken() {
    return JSON.parse(localStorage.getItem('auth')) ? JSON.parse(localStorage.getItem('auth')).refresh : '';
  }
  refreshToken(): Observable<IResponseAuth> {
    if (this.getRefreshToken()) {
      return this.http.post<IResponseAuth>(`${this.BASE_URL}/jwt/refresh`, { refresh: this.getRefreshToken() }).pipe(map(resp => {
        localStorage.setItem('auth', JSON.stringify(resp));
        this.getCurrentUser().subscribe(res => {
          this.addCurrentUser(res);
        });
        return resp;
      }));
    } else {
      localStorage.clear();
      return new Observable<any>();
    }

  }

  public isAuthenticated(): boolean {
    const token = this.getAuthToken();
    return !this.jwtHelper.isTokenExpired(token);
  }

  public getDataToken() {
    const token = this.getAuthToken();
    return this.jwtHelper.decodeToken(token);
  }

  addCurrentUser(data: IUser) {
    this.currentUser.next(data);
    this.currentUser.complete();
  }
  getCurrentUser() {
    const token = this.getAuthToken();
    if (this.isAuthenticated()) {
      return this.http.get<IUser>(`${this.BASE_URL}/users/${this.jwtHelper.decodeToken(token).id}`).pipe(map(resp => {
        this.currentUser.next(resp);
        this.currentUser.complete();
        return resp;
      }));
    } else {
      return new Observable<any>();
    }
  }


}
