import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LegalPositionModalComponent } from 'src/app/modals/legal-position-modal/legal-position-modal.component';

@Component({
  selector: 'app-adjudication-list',
  templateUrl: './adjudication-list.component.html',
  styleUrls: ['./adjudication-list.component.scss']
})
export class AdjudicationListComponent implements OnInit {

  constructor(public dialog: MatDialog) { }
  docId;
  showSpinner = false;
  @Input() searchText;
  @Input() searchListLegalPosition;
  @Input() attachListLegalPosition;
  @Input() documentEchrsShow = false;
  @Input() isAuth = true;
  @Output() searchLegalPosition = new EventEmitter();
  @Output() addLegalPosition = new EventEmitter();
  @Output() clickAttachItem = new EventEmitter();

  ngOnInit(): void {
  }


  setModal() {
    const dialog = this.dialog.open(LegalPositionModalComponent);
    dialog.afterClosed().subscribe(res => {
      this.addLegalPosition.emit(res);
    });
  }

  inputSearch() {
    this.showSpinner = true;
    this.searchLegalPosition.emit(this.searchText);
  }

  onClickAttachItem(data) {
    this.clickAttachItem.emit(data);
  }
}
