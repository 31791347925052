import { AfterContentChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICategories } from 'src/app/models/categories';

@Component({
  selector: 'app-settings-tree-view',
  templateUrl: './settings-tree-view.component.html',
  styleUrls: ['./settings-tree-view.component.scss']
})
export class SettingsTreeViewComponent {

  constructor() { }

  @Input() settingOn: boolean = false;
  @Input() id = 'none';
  currentData: ICategories[];
  @Output() onSaveNewItem = new EventEmitter<any>();
  @Output() onDeleteItem = new EventEmitter<any>();
  @Output() onEditItem = new EventEmitter<any>();
  @Output() onChangeItems = new EventEmitter();
  checkedIds = [];
  checkedsItems = [];


  // динамічне додавання подій
  ngAfterContentChecked() {
    const crossed = document.getElementsByClassName('hideSubList');
    for (let i = 0; i < crossed.length; i++) {
      (crossed[i] as HTMLElement).onclick = this.hideList;
    }
    const menus = document.getElementsByClassName('btn-open-menu');
    for (let i = 0; i < menus.length; i++) {
      (menus[i] as HTMLElement).onclick = this.openItemMenu;
    }
    const overlay = document.getElementsByClassName('overlay-menu');
    for (let i = 0; i < overlay.length; i++) {
      (overlay[i] as HTMLElement).onclick = this.hideMenus;
    }
    const addNewItem = document.getElementsByClassName('add-element');
    for (let i = 0; i < addNewItem.length; i++) {
      (addNewItem[i] as HTMLElement).onclick = this.addNew;
    }
    const saveNewItem = document.getElementsByClassName('save-editable');
    for (let i = 0; i < saveNewItem.length; i++) {
      (saveNewItem[i] as HTMLElement).onclick = this.saveNewItem;
    }
    const deleteNewItem = document.getElementsByClassName('delete-element');
    for (let i = 0; i < deleteNewItem.length; i++) {
      (deleteNewItem[i] as HTMLElement).onclick = this.deleteItem;
    }
    const cancelItems = document.getElementsByClassName('cancel-element');
    for (let i = 0; i < cancelItems.length; i++) {
      (cancelItems[i] as HTMLElement).onclick = this.cancelItem;
    }

    const editItems = document.getElementsByClassName('edit-element');
    for (let i = 0; i < editItems.length; i++) {
      (editItems[i] as HTMLElement).onclick = this.editItem;
    }
    const checkedItems = document.getElementsByClassName('hide-checkbox');
    for (let i = 0; i < checkedItems.length; i++) {
      (checkedItems[i] as HTMLElement).onclick = this.checkedItems;
    }

  }
  // ініціалізація дерева
  initTreeView = (data, selectedItems = []) => {
    this.currentData = data;
    const category = document.getElementById('categories'+this.id);
    category.innerHTML = this.drawTree(data, this.settingOn);
    setTimeout(() => {
      for (const item of selectedItems) {
        this.checkedItemById(item.id, true, false);
        if(item.parentId) {
          this.openOrHideListById(item.parentId,  true);
        }
      }
    }, 100);
  }
  // закриває меню на оверлей
  hideMenus(e?) {
    const menus = document.getElementsByClassName('setting-menu');
    for (let i = 0; i < menus.length; i++) {
      (menus[i] as HTMLElement).classList.remove('active');
    }
  }
  // відкриває меню
  openItemMenu(e) {
    e.target.offsetParent.classList.toggle('active');
  }
  // знаходження об'єкта в масиві по ід
  findChild = (array = [], id) => {
    for (const item of array) {
      const result = item.id === id ? item : this.findChild(item.children, id);
      if (result) return result;
    }
  };
  // згортає список
  hideList = (e) => {
    this.openOrHideListById(e.target.dataset.itemId);
  }

  openOrHideListById(id, open = false) {
    const result = this.findChild(this.currentData, +id);
    if(result?.hasOwnProperty('opened') && !open) {
      result['opened'] = !result['opened'];
      if(!result['opened']) {
        this.hideListAllChild(result);
      }
    } else {
      result['opened'] = true;
    }
    this.initTreeView(this.currentData);
    if (result?.parentId) {
      this.openOrHideListById(result?.parentId, true);
    }
  }

  // згортає всі дочерні списки, коли натиснули на батьківський, і є відкриті дочерні
  hideListAllChild(item) {
    for (const child of item.children) {
      child['opened'] = false;
      if(child.children.length) {
        this.hideListAllChild(child)
      }
    }
  }
  // заміна тимчасового елементу, на той, який прийшов з серверу
  addNewItem(respItem) {
    let parentChild = this.findChild(this.currentData, respItem.parentId);
    parentChild.children.push(respItem);
    this.initTreeView(this.currentData);
  }
  // збереження тимчасового елементу в списку, до моменту поки не прийде новий з беку
  saveNewItem = (e)=> {
    const currentItem = document.getElementById(`${this.id}input-${e.target.dataset.itemId}`) as HTMLInputElement;
    let savedItem = this.findChild(this.currentData, +e.target.dataset.itemId);
    if(savedItem) {
      this.onEditItem.emit({id:savedItem.id, title: currentItem.value});
      savedItem['editable'] = false;
      savedItem.title = currentItem.value;
      this.initTreeView(this.currentData);
    } else {
      console.log('e.target.dataset', e.target.dataset);

      this.onSaveNewItem.emit({
        "title": currentItem.value,
        "parent": { "@id": `/categories/${e.target.dataset.parentId}` }
      });
    }
    // @id: "/categories/2"
    const currentCategory = document.getElementById(`${this.id}category-${e.target.dataset.itemId}`)
    currentCategory.classList.remove('editable');
    const currentTitle = document.getElementById(`${this.id}title-category-${e.target.dataset.itemId}`)
    currentTitle.innerHTML = currentItem.value;
  }
  // додавання тимчасового елементу в список
  addNew = (e) => {
    this.openOrHideListById(e.target.dataset.itemId, true);
    setTimeout(() => {
      const currentItem = document.getElementById(`${this.id}category-item-${e.target.dataset.itemId}`);
      currentItem.innerHTML += this.drawTree([{
        title: '',
        id: Date.now(),
        children: [],
        editable: true,
        parentId: e.target.dataset.itemId,
        temporary: true
      }], this.settingOn);
    }, 0);
    this.hideMenus();
  }
  // видалення елементу
  deleteItem = (e) => {
    const currentItem = document.getElementById(`${this.id}category-item-${e.target.dataset.itemId}`);
    const parentItem = this.findChild(this.currentData, +e.target.dataset.parentId);
    currentItem.remove();
    const deletedItem = parentItem?.children.find(item => item.id === +e.target.dataset.itemId);
    if(parentItem) {
      parentItem.children = parentItem?.children.filter(child => child.id !== +e.target.dataset.itemId);
    }
    if(deletedItem) {
      this.onDeleteItem.emit(e.target.dataset.itemId);
    }
    this.hideMenus();
  }

  cancelItem = (e) => {
    e.stopPropagation()

    const currentItem = document.getElementById(`${this.id}category-item-${e.target.dataset.itemId}`);
    const currentItemC = this.findChild(this.currentData, +e.target.dataset.itemId);

    if(currentItemC && currentItemC['editable']) {
      currentItemC['editable'] = false;
      this.initTreeView(this.currentData);
    } else {
      currentItem?.remove();
    }
    this.hideMenus();

  }
  // редагування елементу
  editItem = (e) => {
    const editableItem = this.findChild(this.currentData, +e.target.dataset.itemId);
    editableItem['editable'] = true;
    this.initTreeView(this.currentData);
  }
  checkedItems = (e) => {
    this.checkedItemById(+e.target.dataset.itemId, e.target.checked);
  }
  checkedItemById(id, checked, emit = true) {
    const editableItem = this.findChild(this.currentData, id);
    if(editableItem) {
      editableItem['checked'] = checked;
      this.setCheckedForAllChild(editableItem, checked);
      this.initTreeView(this.currentData);
    }
    if (emit) {
      this.onChangeItems.emit();
    }
  }
  setCheckedForAllChild(item, checked) {
    for (const child of item.children) {
      child['checked'] = checked;
      if(child.children.length) {
        this.setCheckedForAllChild(child, checked)
      }
    }
  }



  getCheckedIds() {
    this.checkedIds = [];
    this.findCheckeChild(this.currentData, 'id');
    return this.checkedIds;
  }

  getCheckedItems() {
    this.checkedsItems = [];
    this.findCheckeChild(this.currentData, 'child');
    return this.checkedsItems;
  }

  findCheckeChild(children, type) {
    for (const child of children) {
      if(child['checked']) {
        if(type == 'id') {
          this.checkedIds.push(child.id);
        }
        if(type == 'child') {
          this.checkedsItems.push(child);
        }
      }
      if(child.children.length) {
        this.findCheckeChild(child.children, type);
      }
    }
  }

  // відмальовування дерева
  drawTree(el, settingOn) {
    let s=""; //
    for (const X in el) {
        if (el[X]) {
            s+=`<div class="category-item ${el[X].opened ? 'active-list' : ''}" id="${this.id}category-item-${el[X].id}">
              <div class="category-title ${el[X]?.editable ? 'editable' : ''}" id="${this.id}category-${el[X].id}">
                <input type="checkbox" class="hide-checkbox" id="${this.id}casaciyniy-${el[X].id}" hidden ${el[X].checked ? 'checked' : ''} data-item-id="${el[X].id}">
                ${settingOn ?
                `<div class="editable-block">
                  <input class="input-text" type="text" placeholder="Введіть назву" id="${this.id}input-${el[X].id}" value="${el[X].title}">
                  <div class="controll-editable">
                    <span class="save-editable" data-item-id="${el[X].id}" data-parent-id="${el[X].parentId}"> Зберегти </span>
                    <span class="delete-editable cancel-element" data-item-id="${el[X].id}"> <i class="icon-delete" data-item-id="${el[X].id}"></i> </span>
                  </div>
                </div>`
                : ''}
                <label id="${this.id}title-category-${el[X].id}" class="${el[X].children?.length ? 'name' : ''}" for="${this.id}casaciyniy-${el[X].id}">${el[X].title} </label>
                ${el[X].children?.length ? `<i class="icon-arrow hideSubList" data-item-id="${el[X].id}"></i>` : ''}
                ${settingOn ?
                `<div class="setting-menu">
                   <div class="btn-open-menu"> <i class="icon-menu-dots"></i> </div>
                  <div class="overlay-menu"></div>
                  <div class="menu-list">
                    <div class="menu-list-item add-element" data-item-id="${el[X].id}"> <i class="icon-cross" data-item-id="${el[X].id}"></i> Додати</div>
                    <div class="menu-list-item edit-element" data-item-id="${el[X].id}"> <i class="icon-change-title" data-item-id="${el[X].id}"></i> Змінити назву</div>
                    <div class="menu-list-item delete-element" data-item-id="${el[X].id}" data-parent-id="${el[X].parentId}"> <i class="icon-delete" data-item-id="${el[X].id}"></i> Видалити</div>
                  </div>
                </div>`
                : ''}
              </div>
            `;
            if (el[X].children && el[X].opened) {
                s+=this.drawTree(el[X].children, settingOn);
            }
            s+="</div>";
        }
    }
    return s
  }
}
