<div class="period-filter">
  <div class="title">
    <i class="icon-date-icon"></i>
    <span>{{titleDateFilter}}</span>
  </div>
  <div class="select-dates">
    <div class="select-container">
      <label for="from">Від</label>
      <mat-form-field appearance="fill">
        <input
        matInput
        [matDatepicker]="pickerFrom"
        placeholder="Обрати"
        [max]="dateTo"
        id="from"
        (dateChange)="changeDate()"
        [(ngModel)]="dateFrom">
        <mat-datepicker-toggle matSuffix [for]="pickerFrom">
          <mat-icon matDatepickerToggleIcon><i class="icon-date"></i></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="select-container">
      <label for="to">До</label>
      <mat-form-field appearance="fill">
        <input
        matInput
        [matDatepicker]="pickerTo"
        placeholder="Обрати" id="to"
        [min]="dateFrom"
        (dateChange)="changeDate()"
        [(ngModel)]="dateTo">
        <mat-datepicker-toggle matSuffix [for]="pickerTo">
          <mat-icon matDatepickerToggleIcon><i class="icon-date"></i></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</div>
