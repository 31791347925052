import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { generateString } from '../../../helpers/random-generator';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input() isAuth: boolean;
  @Input() currentUser;
  @Output() ToggleSidebar = new EventEmitter();
  profileStatus = false;

  @Input() sidebarVisible = false;

  toggleSideber() {
    this.ToggleSidebar.emit();
  }

  setProfileStatus(status) {
    this.profileStatus = status;
  }

  getFullName(currentUser) {
    return `${currentUser?.person.firstName} ${currentUser?.person.lastName} ${currentUser?.person.middleName}`;
  }

  getRandomKey() {
    return generateString(12);
  }
}
