<div class="default-modal-template-block">
  <div
  class="background-toggle"
  (click)="dialogRef.close()"
></div>
<div class="background-blur"></div>

<div class="default-modal-template classifier-modal">
  <button
  class="close-modal"
  (click)="dialogRef.close()"
>
  <i class="icon-cross"></i>
</button>
<div class="top-container">
  <div class="image-container">
    <img src="../../../assets/images/classifier-icon.svg" alt="" />
  </div>
  <div class="text-container">
    <h2>Класифікатор правових позицій</h2>
  </div>

</div>
<app-separator [fullW]="true" [bgW]="true"></app-separator>

  <div class="form-container">

    <app-settings-tree-view
    [settingOn]="true"
    #settingsTreeView
    [id]="'settings'"
    (onSaveNewItem)="onSaveNewItem($event)"
    (onDeleteItem)="onDeleteItem($event)"
    (onEditItem)="onEditItem($event)"
    ></app-settings-tree-view>
  </div>

<div class="buttons-container" *ngIf="data?.showFooterBtn">
  <button class="button-cancel" (click)="dialogRef.close()">
    Повернутися назад
  </button>

    <button class="button-send" (click)="onSubmit()">
      <span>Обрати</span>
    </button>
</div>
</div>

</div>
