import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DatePeriodComponent } from '../../common-components/date-period/date-period.component';

@Component({
  selector: 'app-court-decision-filter',
  templateUrl: './court-decision-filter.component.html',
  styleUrls: ['./court-decision-filter.component.scss']
})
export class CourtDecisionFilterComponent implements OnInit {

  @Input() list;
  @Input() titleDateFilter;
  @Input() selectedIds = [];
  @Input() filters;
  @Input() titleFilter = 'Статус остаточного';
  @Output() selectedItems = new EventEmitter();
  @Output() clearAllFilter = new EventEmitter();
  @ViewChild(DatePeriodComponent) datePeriods: DatePeriodComponent;
  constructor() { }
  ngOnInit(): void {
    this.selectedIds = this.filters?.courtCodes || this.filters?.selectedIds || [];
  }


  hideListFilter = true;
  dates;
  selectEl(e, el) {
    if(e.target.checked) {
      this.selectedIds.push(el.courtCode)
    } else {
      this.selectedIds.forEach((item, i) => {
        if (item === el.courtCode) this.selectedIds.splice(i, 1);
      })
    }
    this.selectedItems.emit({selectedIds: this.selectedIds, dates: this.dates});
  }

  changeDates(dates) {
    this.dates = dates;
    this.selectedItems.emit({selectedIds: this.selectedIds, dates});
  }


  findCheckedItem(el) {
    return this.selectedIds?.length ? this.selectedIds.find(id => id === el.courtCode) : null;
  }

  clearFilters() {
    this.datePeriods.clearDates();
    this.selectedIds = [];
    this.dates = {};
    this.clearAllFilter.emit();
  }
}
