

<div class="notifications-local-container">

  <div *ngFor="let element of notifications;let i = index" class="notification-local-item" [ngClass]="{error: element.error}">
    <div class="inner-green-container" *ngIf="!element.error; else errorContainer">
      <div class="title">
        <span>{{element.title}}</span>
      </div>
      <button class="close-modal" (click)="remove(i)">
        <i class="icon-cross"></i>
      </button>
    </div>
    <ng-template #errorContainer>
      <div class="inner-error-container">
        <div class="title">
          <i class="icon-new-info"></i>
          <span>{{element.title}}</span>
        </div>
        <div class="text">
          <span>{{element.text}}</span>
        </div>
        <button class="close-modal" (click)="remove(i)">
          <i class="icon-cross"></i>
        </button>
      </div>
    </ng-template>
  </div>
</div>
