import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RestoreComponent } from './restore/restore.component';
import { RegisterComponent } from './register/register.component';
import { CreatePasswordComponent } from './create-password/create-password.component';


const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: 'restore', component: RestoreComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'create-password/:id', component: CreatePasswordComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
