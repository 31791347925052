<div class="user-details-modal-block" [formGroup]="userDetailsForm">
  <div class="user-details-modal">
    <button class="close-modal" (click)="dialogRef.close()">
      <i class="icon-cross"></i>
    </button>
    <div class="top-container">
      <h2>Деталі користувача</h2>
    </div>
    <div class="form-container">
      <div class="image-container">
        <img *ngIf="false" src="https://www.pngkit.com/png/full/796-7963534_individuals-person-icon-circle-png.png"
          alt="" />
        <div class="avatar-praceholder">
          <i class="icon-profile-placeholder"></i>
        </div>
      </div>
      <ng-container formGroupName="person">
        <div class="input-columns">
          <div class="input-container"
            [ngClass]="{'placeholder-disabled': f('person').get('firstName').value, error: (f('person').get('firstName').invalid && (f('person').get('firstName').dirty || f('person').get('firstName').touched)) }">
            <label>Ім’я</label>
            <div class="input-wrapper">
              <input type="text" formControlName="firstName" />
              <span class="placeholder">Введіть</span>
            </div>
          </div>
          <div class="input-container"
            [ngClass]="{'placeholder-disabled': f('person').get('lastName').value, error: (f('person').get('lastName').invalid && (f('person').get('lastName').dirty || f('person').get('lastName').touched)) }">
            <label>Прізвище</label>
            <div class="input-wrapper">
              <input type="text" formControlName="lastName" />
              <span class="placeholder">Введіть</span>
            </div>
          </div>


        </div>
        <div class="input-container"
          [ngClass]="{'placeholder-disabled': f('person').get('middleName').value, error: (f('person').get('middleName').invalid && (f('person').get('middleName').dirty || f('person').get('middleName').touched)) }">
          <label>По батькові</label>
          <div class="input-wrapper">
            <input type="text" formControlName="middleName" />
            <span class="placeholder">Введіть</span>
          </div>
        </div>
      </ng-container>
      <div class="input-container"
        [ngClass]="{'placeholder-disabled': f('email').value, error: (f('email').invalid && (f('email').dirty || f('email').touched)) }">
        <label>Email</label>
        <div class="input-wrapper">
          <input type="text" formControlName="email" />
          <span class="placeholder">Введіть</span>
        </div>
      </div>
      
      <ng-container *appCheckRole="'admin'">
          <ng-container *ngIf="data.user.type !== 'admin';else elseNoAdmin">
            <hr />
            <div class="container-check-text">
              <div class="checkbox-container">
                <input
                  type="radio"
                  id="enable-admin"
                  formControlName="type"
                  name="type"
                  value="admin"
                  (click)="checkSelected('admin')"
                />
                <label for="enable-admin">
                  <div class="checkbox-button"></div>
                  Адміністратор
                </label>
              </div>
              <p class="operator-text">
                Адміністратор має усі права старшого оператора та право визначати 
                права користувача (користувач, оператор, старший оператор).
              </p>
            </div>
            <div class="container-check-text">
              <div class="checkbox-container">
                <input
                  type="radio"
                  id="enable-top-operator"
                  formControlName="type"
                  name="type"
                  value="operator_high"
                  (click)="checkSelected('operator_high')"
                />
                <label for="enable-top-operator">
                  <div class="checkbox-button"></div>
                  Старший оператор
                </label>
              </div>
              <p class="operator-text">
                Старший оператор має всі права оператора та право затверджувати 
                правову позицію та видаляти її.
              </p>
            </div>
            <div class="container-check-text">
              <div class="checkbox-container">
                <input
                  type="radio"
                  id="enable-operator"
                  formControlName="type"
                  name="type"
                  value="operator"
                  (click)="checkSelected('operator')"
                />
                <label for="enable-operator">
                  <div class="checkbox-button"></div>
                  Оператор
                </label>
              </div>
              <p class="operator-text">
                Оператор має право створювати, редагувати правову позицію без її
                затвердження. Оператор має усі права керування судовим рішенням.
              </p>
            </div>
          </ng-container>


          <ng-template #elseNoAdmin>

            <hr />
              <div class="checkbox-container">
                <label htmlFor="enable-operator">Адміністратор</label>
              </div>
          </ng-template>
          </ng-container>

    </div>

    <div class="buttons-container">
      <button class="button-deactivate" (click)="removeUser()">Видалити</button>
      <button class="button-send" (click)="onSubmit()">
        Зберегти
      </button>
    </div>
  </div>
