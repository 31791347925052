<div class="profile-info"
[ngClass]="{active: profileStatus}">
  <div class="info-title">
    <div class="avatar">
        <!-- <img
          src="https://www.pngkit.com/png/full/796-7963534_individuals-person-icon-circle-png.png"
          alt=""
        /> -->
        <div class="avatar-praceholder">
          <i class="icon-profile-placeholder"></i>
        </div>
    </div>
    <h2 class="name">
      {{userName}}
    </h2>
  </div>
  <a routerLink="/profile" (click)="toggleProfile()">
    Налаштування профілю
  </a>
  <button (click)="logoutUserAction()">
    <i class="icon-exit"></i>
    Вийти
  </button>
</div>
<div
  class="profile-bg-toggle"
  [ngClass]="{active: profileStatus}"
  (click)="toggleProfile()"
></div>