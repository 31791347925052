<div class="auth-block" *ngIf="!errorChangePass">
  <div class="title-container">
    <h2>Створіть новий ID-ключ</h2>
    <p>Будь ласка, вигадайте новий захищений ключ до системи</p>
  </div>
  <div class="form-container" [formGroup]="createPasswordForm">
    <div class="inputs">
      <div class="input-container with-icon"
      [ngClass]="{
        error: (f('password').invalid && (f('password').dirty && f('password').touched)),
        'placeholder-disabled': f('password').value}">
        <input [type]="showPassword ? 'text' : 'password'" formControlName="password">
        <div class="icons-container">
          <div class="icon">
            <i class="icon-password"></i>
          </div>
          <button class="button-password" (click)="showPassword = !showPassword"><i class="icon-eye"></i></button>
        </div>
        <div class="placeholder">Вигадайте пароль</div>
      </div>
      <div aria-hidden="true" class="error-container" [ngClass]="{'show': (f('password').invalid && (f('password').dirty && f('password').touched))}">
        <div >
          <p class="error-text">
            Ваш пароль повинен містити принаймні одну велику літеру, одну малу літеру, одну цифру і хоча б один спеціальний символ (!, +, - , %, ^) та загальну довжину не менше 8 символів
          </p>
        </div>
      </div>
      <div class="input-container with-icon"
        [ngClass]="{
          error: (validReapetPassword && (f('passwordRepeat').dirty && f('passwordRepeat').touched)),
          'placeholder-disabled': f('passwordRepeat').value}">
        <input [type]="showPasswordRepeat ? 'text' : 'password'" formControlName="passwordRepeat" (input)="checkReapetPass($event)">
        <div class="icons-container">
          <div class="icon">
            <i class="icon-password"></i>
          </div>
          <button class="button-password" (click)="showPasswordRepeat = !showPasswordRepeat"><i class="icon-eye"></i></button>
        </div>
        <div class="placeholder">Повторіть пароль</div>
      </div>
      <div aria-hidden="true" class="error-container" [ngClass]="{'show': (validReapetPassword && (f('passwordRepeat').dirty && f('passwordRepeat').touched))}">
        <div >
          <p class="error-text">
            Паролі не збігаються. Спробуйте ще раз.
          </p>
        </div>
      </div>
    </div>
    <button class="button-submit" (click)="onSubmit()">
      Увійти до системи
      <i class="icon-back"></i>
    </button>
  </div>
</div>

<div *ngIf="errorChangePass" class="auth-block authswitchfade-enter-done">
  <div class="title-container">
    <h2>Посилання недійсне</h2>
    <p>Посилання некоретне або застаріло. Будь ласка, спробуйте виконати дію повторно</p>
  </div>
  <div class="form-container">
    <button class="button-submit single-button" routerLink="/">
      Повернутися до входу
      <i class="icon-back"></i>
    </button>
  </div>
</div>
