import { JwtHelperService } from '@auth0/angular-jwt';
import { IUser } from '../models/user';

/**
 * Why not ENUM
 * @link https://medium.com/@maxheiber/alternatives-to-typescript-enums-50e4c16600b1
 * Also ENUM doesn't give us at least type assurance
 * Yes, I know about ETeamMemberRoles
 * @see ETeamMemberRoles
 */

export enum ERole {
   'admin' = 'ROLE_ADMIN',
   'operator' = 'ROLE_OPERATOR',
   'operator_high' = 'ROLE_OPERATOR_HIGH'
 }

export type Roles =
  | 'admin'
  | 'operator'
  | 'user'
  | 'operator_high';

/**
 * Determine if current User has any of given roles
 * @param roles List of ALLOWED roles
 */
export function checkRole(roles: Roles | Roles[]): boolean {


  const requiredRoles = Array.isArray(roles) ? roles : [roles];


  const jwt = new JwtHelperService();

  /**
   * Check JWT for existence in localstorage
   */
  const JWT = localStorage.getItem('auth');
  if (!JWT) {
    return false;
  }
  /**
   * According to AuthService.getPayload
   * But we cannot inject it here
   */
  const user = jwt.decodeToken(localStorage.getItem('auth')) as IUser;

  /**
   * @link https://stackoverflow.com/a/39893636
   */

  return requiredRoles.some(role => user.roles.includes(ERole[role]));
}
