import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-court-decisions-field',
  templateUrl: './new-court-decisions-field.component.html',
  styleUrls: ['./new-court-decisions-field.component.scss']
})
export class NewCourtDecisionsFieldComponent implements OnInit {


 @Input() dataInfo;
@Input() type; 
  constructor() { }

  ngOnInit(): void {
  }

}
