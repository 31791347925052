<div class="list-item">
  <a class="list-inner" routerLink="/decision-yespl/card/{{data?.id}}">
    <div class="icon-container">
      <img *ngIf="data?.decidedDate" src="../../../../assets/images/decision-yespl-item.svg" alt="" />
      <img *ngIf="!data?.decidedDate" src="../../../../assets/images/decision-yespl-item-gray.svg" alt="" />

    </div>
    <div class="content-container">
      <span class="link-item">
        <h5>{{data?.title}}</h5>
      </span>
      <p>{{data?.description}}</p>
    </div>
  </a>
</div>
