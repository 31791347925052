import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  constructor() { }
  @Input() currentPage;
  @Input() lastPage;
  @Input() itemsPerPage;
  @Output() ToPage = new EventEmitter();
  @Output() ItemsPerPage = new EventEmitter();

  perPageItems = [
    { value: 50 },
    { value: 100 },
    { value: 150 },
    { value: 200 },
  ];

  ngOnInit(): void {
  }
  toPage(page) {
    this.currentPage = page;
    this.ToPage.emit(page);
  }
  setPerPage(perPage) {
    this.itemsPerPage = perPage;
    this.ItemsPerPage.emit(perPage);
  }
}
