import { Injectable } from '@angular/core';
import { IUser } from 'src/app/models/user';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Helpers } from 'src/app/helpers/helpers';
import { ResponsePagination } from 'src/app/models/response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private BASE_URL = `${environment.searchUrl}/users`;

  constructor(private http: HttpClient, private authService: AuthService) {}

  get(currentPage = environment.defaultPage, itemsPerPage = environment.defaultItemsCount, filters = null) {
    let params = new HttpParams().set('partial', 'false');

    if (currentPage && itemsPerPage) {
      params = params.set('page', currentPage.toString()).set('itemsPerPage', itemsPerPage.toString());
    }

    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }
    if (this.authService.isAuthenticated()) {
      return this.http
        .get<ResponsePagination<IUser>>(this.BASE_URL, { params });
    } else {
      return new Observable<ResponsePagination<IUser>>();
    }
  }

  updateUser(id, data) {
    return this.http.patch(`${this.BASE_URL}/${id}`, data);
  }

  removeUser(id) {
    return this.http.delete(`${this.BASE_URL}/${id}`);
  }

  changePassword(data) {
    return this.http.patch(`${environment.searchUrl}/registration/password/change?v2`, data);
  }

  resetPassword(email) {
    return this.http.post(`${environment.searchUrl}/registration/password/reset`, {email});
  }
}
