import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface INotification {
  title: string;
  text: string;
  error: boolean;
  delay: number;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  subject: BehaviorSubject<INotification>;
  notification$: Observable<INotification>;

  constructor() {
    this.subject = new BehaviorSubject<INotification>(null);
    this.notification$ = this.subject.asObservable()
      .pipe(filter(notification => notification !== null));
  }

  show(error, title?: string, text?: string, delay?: number) {
    this.subject.next({ error, title, text, delay });
  }
}
