import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-header-profile',
  templateUrl: './header-profile.component.html',
  styleUrls: ['./header-profile.component.scss']
})
export class HeaderProfileComponent implements OnInit {

  constructor(private authService: AuthService) { }
  @Input() profileStatus;
  @Input() currentUser;
  @Input() userName;
  @Output() ToggleProfile = new EventEmitter();
  ngOnInit(): void {
  }

  toggleProfile() {
    this.ToggleProfile.emit();
  }

  logoutUserAction() {
    this.authService.logout();
  }
}
