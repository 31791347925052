<aside class="private-sidebar" [ngClass]="{open: sidebarVisible}">
  <div class="title-container">
    <h2>Навігація</h2>
  </div>
  <div class="link-container">
  <app-sidebar-item
    *ngFor="let route of routerList"
    [route]="route"
    [isAuth]="isAuth"
    (toggle)="toggleSideber()"
  ></app-sidebar-item>
  </div>
  <div class="sidebar-animation">
    <img src="../../../../assets/images/sidebar-animation.svg" alt="" />
  </div>
</aside>
  <div *ngIf="sidebarVisible" class="private-aside-bg" (click)="toggleSideber()"></div>
