<div class="auth-block">
  <div class="title-container">
    <h2>Увійти до системи</h2>
    <p>Інтелектуальна система пошуку за правовими позиціями</p>
  </div>
  <div class="form-container" [formGroup]="loginForm">
    <div class="inputs">
      <div class="input-container with-icon"
      [ngClass]="{
        error: (f('email').invalid && (f('email').dirty && f('email').touched)),
        'error-login': badRequest,
        'placeholder-disabled': f('email').value}">
        <input placeholder="Введіть email" type="email" email #email formControlName="email" autocomplete="email" (focus)="badRequest = false">
        <div class="icons-container">
          <div class="icon">
            <i class="icon-email"></i>
          </div>
        </div>
      </div>
      <div aria-hidden="true" class="error-container" [ngClass]="{'show': (f('email').invalid && (f('email').dirty && f('email').touched))}">
        <div>
          <p class="error-text">
            Неправильний email. Будь ласка, спробуйте інший
          </p>
        </div>
      </div>
      <div class="input-container with-icon"
      [ngClass]="{'error': f('password').invalid && (f('password').dirty && f('password').touched),
        'error-login': badRequest,
        'placeholder-disabled': f('password').value}">
        <input [type]="showPass ? 'text' : 'password'" placeholder="Введіть пароль" formControlName="password" (focus)="badRequest = false">
        <div class="icons-container">
          <div class="icon">
            <i class="icon-password"></i>
          </div>
          <button class="button-password" (click)="visiblePass()">
            <i class="icon-eye"></i>
          </button>
        </div>
      </div>
      <div aria-hidden="true" class="rah-static rah-static--height-zero" style="height: 0px; overflow: hidden;">
        <div style="display: none;">
          <p class="error-text"></p>
        </div>
      </div>
    </div>
    <!-- (single ? " single-button" : "") +
    (loading ? " loading" : "") -->
    <!-- onClick={clickCallback}
    disabled={loading} -->
    <button class="button-submit" (click)="onSubmit()">
    Увійти до системи
    <i class="icon-back"></i>
    </button>
    <div aria-hidden="false" class="error-msg error-container" [ngClass]="{'show': badRequest}">
      <div [ngStyle]="{display: badRequest ? 'block' : 'none'}">
        <div class="global-error">Введено неправильні дані. Будь ласка, спробуйте ще раз</div>
      </div>
    </div>
    <div class="links">
      <p>
        Немає акаунту? <a routerLink="/register">Створіть його</a>
      </p>
      <a routerLink="/restore">Забули свій пароль?</a>
    </div>
  </div>
</div>
