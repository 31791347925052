import { Component, OnInit } from '@angular/core';
import { NotificationsService } from './notifications.service';

@Component({
  selector: 'app-notification-local',
  templateUrl: './notification-local.component.html',
  styleUrls: ['./notification-local.component.scss']
})
export class NotificationLocalComponent implements OnInit {

  notifications = [];

  constructor(private notificationService: NotificationsService) {}

  ngOnInit() {
    this.notificationService.notification$
      .subscribe(notification => {
        this.notifications = [notification, ...this.notifications];
        setTimeout(() => this.notifications.pop(), notification.delay || 8000);
      });
  }

  remove(index: number) {
    this.notifications = this.notifications.filter((v, i) => i !== index);
  }
}
