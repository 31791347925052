import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import { AuthService } from '../services/auth.service';

export interface ILoginData {
  email?: string;
  password?: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private authService: AuthService,
    public router: Router) { }
  loginForm = new FormGroup({
    email: new FormControl(!environment.production ? 'admin@test.ua' : '', [Validators.required]),
    password: new FormControl(!environment.production ? 'Password123' : '', [Validators.required]),
  });
  showPass = false;
  badRequest = false;


  @ViewChild('email') email;
  visiblePass() {
    this.showPass = !this.showPass;
  }
  f(control) { return this.loginForm.get(control); }

  validateF(control) {
    return this.f(control).invalid && (this.f(control).dirty || this.f(control).touched);
  }
  ngOnInit(): void {

  }
  onSubmit() {
    if (this.loginForm.valid) {
      const loginData: ILoginData = {...this.loginForm.value};

      this.authService.login(loginData).subscribe(res => {
        if (res.error?.code === 401) {
          this.badRequest = true;
        } else {
          this.router.navigateByUrl('/');
        }
      });
    }
  }
}
