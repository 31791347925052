<div class="pagination">
  <button
    class="arrow arrow-left"
    [disabled]="currentPage === 1"
    (click)="toPage(currentPage - 1)"
  >
    <i class="icon-arrow"></i>
  </button>
  <button
    class="arrow arrow-right"
    [disabled]="currentPage === lastPage"
    (click)="toPage(currentPage + 1)"
  >
    <i class="icon-arrow"></i>
  </button>
  <p>
    {{currentPage}} з {{lastPage}}
  </p>
  <div class="per-page">
    {{itemsPerPage}}
    <i class="icon-arrow"></i>
    <div class="per-page-list">
      <div class="menu-wrapper">
          <div
            *ngFor="let element of perPageItems"
            class="menu-item"
            (click)="setPerPage(element.value)"
          >
            {{element.value}}
          </div>
      </div>
    </div>
  </div>
</div>