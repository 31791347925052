import { Component, OnInit, Inject, ViewChild, ViewChildren, ViewContainerRef, Directive } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettingsTreeViewComponent } from 'src/app/modules/common-components/settings-tree-view/settings-tree-view.component';
import { SearchService } from 'src/app/modules/search/search.service';


@Component({
  selector: 'app-classifier-settings-modal',
  templateUrl: './classifier-settings-modal.component.html',
  styleUrls: ['./classifier-settings-modal.component.scss']
})

export class ClassifierSettingsModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ClassifierSettingsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private searchService: SearchService) { }

  
  @ViewChild('settingsTreeView') treeView: SettingsTreeViewComponent;
  selectedCategories = [];

  ngOnInit() {
    this.selectedCategories = this.data?.selectedCategories;
  }
  ngAfterViewInit() {
    this.getCategories();
  }
  getCategories() {
    if (!this.searchService.currentCategories) {
      this.searchService.getCategories();
    } else {
      this.treeView.initTreeView(this.searchService.currentCategories, this.selectedCategories);
    }
    this.searchService.onCategories.subscribe(resp => {
      this.treeView.initTreeView(resp, this.selectedCategories);
    })
  }

  onSaveNewItem(data) {
    this.searchService.postCategories(data).subscribe(resp => {
      this.treeView.addNewItem(resp)
    })
  }
  onDeleteItem(id) {
    this.searchService.deleteCategories(id).subscribe(resp => {
    })
  }
  onEditItem(id) {
    this.searchService.putCategories(id).subscribe(resp => {
    })
  }

  onSubmit() {
    this.dialogRef.close({
      selectedCategories: this.treeView.getCheckedItems(),
      selectedCategoriesIds: this.treeView.getCheckedIds(),
    });
  }
}
