import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-court-decisions-nav',
  templateUrl: './new-court-decisions-nav.component.html',
  styleUrls: ['./new-court-decisions-nav.component.scss']
})
export class NewCourtDecisionsNavComponent implements OnInit {

  constructor() { }

  @Input() tabs;

  ngOnInit(): void {
  }

}
