<div class="default-modal-template-block center">
  <div
    class="background-toggle"
    (click)="dialogRef.close()"
  ></div>
  <div class="background-blur" (click)="dialogRef.close()"></div>
  <div class="default-modal-template container-check-similar">
    <button
    class="close-modal"
    (click)="dialogRef.close()"
  >
    <i class="icon-cross"></i>
  </button>

    <div class="">

      <div class="title">
        <span class="text">База вже має схожі правові позиції</span>
        <div class="sub-title">
          <span class="sub-text">
            <span>Ми провели автоматичний аналіз та виявили, що в Базі вже є схожі</span> 
            <span>правові позиції. Для запобігання дублювання, перегляньте наступні позиції:</span>
          </span>
        </div>
      </div>
      
      <div class="items">
        <app-adjudication-item
        *ngFor="let item of data.list"
        [data]="item"
        [visibleAttach]="false"
        ></app-adjudication-item>
      </div>
    </div>
    <div class="buttons-container">
      <button class="button-cancel" (click)="dialogRef.close()">
        Скасувати
      </button>
    
      <button class="button-send" (click)="onSubmit()">
        <span>Все одно завантажити</span>
      </button>
    
    </div>
</div>
