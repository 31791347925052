<div class="loading-spinner">
  <svg
    width="43"
    height="43"
    version="1.1"
    viewBox="0 0 43 43"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m5.48 21.5a0.654 0.654 0 0 0-0.656-0.656h-4.168a0.654 0.654 0 0 0-0.656 0.656c0 0.365 0.292 0.656 0.656 0.656h4.168a0.654 0.654 0 0 0 0.656-0.656z" />
    <path
      d="m43 21.5a0.654 0.654 0 0 0-0.656-0.656h-4.168a0.654 0.654 0 0 0-0.656 0.656c0 0.365 0.292 0.656 0.656 0.656h4.168a0.654 0.654 0 0 0 0.656-0.656z"
      fill="#2145de"
    />
    <path d="m41.01 14.132a0.655 0.655 0 0 0 0.406-0.605 0.656 0.656 0 0 0-0.906-0.604l-3.855 1.594a0.656 0.656 0 0 0-0.354 0.854c0.135 0.334 0.52 0.49 0.854 0.355z" />
    <path d="m36.707 7.22a0.646 0.646 0 0 0 0.188-0.458 0.646 0.646 0 0 0-0.188-0.459 0.648 0.648 0 0 0-0.927 0l-2.948 2.949a0.648 0.648 0 0 0 0 0.927c0.26 0.26 0.666 0.26 0.927 0z" />
    <path
      d="m14.132 41.01 1.594-3.855a0.716 0.716 0 0 0 0.052-0.25 0.655 0.655 0 0 0-1.26-0.25l-1.595 3.855a0.656 0.656 0 0 0 0.354 0.854 0.66 0.66 0 0 0 0.855-0.354z"
      fill="#2145de"
    />
    <path d="m27.628 6.699a0.656 0.656 0 0 0 0.855-0.354l1.594-3.855a0.716 0.716 0 0 0 0.052-0.25 0.655 0.655 0 0 0-1.26-0.25l-1.595 3.855a0.656 0.656 0 0 0 0.354 0.854z" />
    <path
      d="m22.156 42.344v-4.168a0.654 0.654 0 0 0-0.656-0.656 0.654 0.654 0 0 0-0.656 0.656v4.168c0 0.364 0.291 0.656 0.656 0.656a0.654 0.654 0 0 0 0.656-0.656z"
      fill="#2145de"
    />
    <path d="m20.844 0.656v4.168c0 0.364 0.291 0.656 0.656 0.656a0.654 0.654 0 0 0 0.656-0.656v-4.168a0.654 0.654 0 0 0-0.656-0.656 0.654 0.654 0 0 0-0.656 0.656z" />
    <path
      d="m27.628 36.3a0.656 0.656 0 0 0-0.354 0.855l1.594 3.855a0.656 0.656 0 0 0 1.26-0.25 0.716 0.716 0 0 0-0.051-0.25l-1.594-3.855a0.656 0.656 0 0 0-0.855-0.354z"
      fill="#2145de"
    />
    <path d="m15.372 6.699a0.655 0.655 0 0 0 0.406-0.604 0.716 0.716 0 0 0-0.052-0.25l-1.594-3.855a0.656 0.656 0 0 0-0.855-0.354 0.656 0.656 0 0 0-0.354 0.854l1.594 3.855c0.136 0.333 0.521 0.49 0.855 0.354z" />
    <path
      d="m36.707 36.707a0.646 0.646 0 0 0 0.188-0.458 0.646 0.646 0 0 0-0.188-0.459l-2.948-2.948a0.648 0.648 0 0 0-0.927 0 0.648 0.648 0 0 0 0 0.927l2.948 2.949c0.25 0.24 0.667 0.24 0.927-0.01z"
      fill="#2145de"
    />
    <path d="m10.366 9.71a0.646 0.646 0 0 0-0.187-0.458l-2.959-2.96a0.648 0.648 0 0 0-0.927 0 0.648 0.648 0 0 0 0 0.928l2.948 2.948c0.26 0.26 0.667 0.26 0.928 0a0.657 0.657 0 0 0 0.197-0.458z" />
    <path
      d="m41.364 29.722a0.716 0.716 0 0 0 0.052-0.25c0-0.26-0.156-0.5-0.406-0.604l-3.855-1.594a0.656 0.656 0 0 0-0.854 0.354 0.656 0.656 0 0 0 0.354 0.855l3.855 1.594a0.647 0.647 0 0 0 0.854-0.355z"
      fill="#2145de"
    />
    <path d="m6.7 15.371a0.716 0.716 0 0 0 0.051-0.25c0-0.26-0.156-0.5-0.406-0.604l-3.855-1.594a0.656 0.656 0 0 0-0.854 0.354 0.656 0.656 0 0 0 0.354 0.855l3.855 1.594a0.656 0.656 0 0 0 0.854-0.355z" />
  </svg>
</div>