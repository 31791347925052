<div class="default-modal-template-block">
  <div
    class="background-toggle"
    (click)="dialogRef.close()"
  ></div>
  <div class="background-blur"></div>
  <div class="default-modal-template digest-modal">
    <button
      class="close-modal"
      (click)="dialogRef.close()"
    >
      <i class="icon-cross"></i>
    </button>
    <div class="top-container">
      <div class="image-container">
        <img src="../../../assets/images/digest.svg" alt="" />
      </div>
      <div class="text-container">
        <h2>Додати дайджест</h2>
        <p>Скористайтеся полями нижче для того, щоб завантажити дайджест до системи.</p>
      </div>
    
    </div>
    <app-separator></app-separator>
    <div class="form-container" [formGroup]="digestForm">
      <div class="input-container" [ngClass]="{error: (f('title').invalid && (f('title').dirty && f('title').touched || clickedSubmit))}">
        <div class="input-title">
          <label>Назва</label>
          <span>Обов’язково</span>
        </div>
        <div class="input-wrapper">
          <input type="text" formControlName="title">
          <div class="placeholder" *ngIf="!f('title').value">Введіть назву дайджеста</div>
        </div>
      </div>
      <div class="input-container textarea-flex" [ngClass]="{error: (f('description').invalid && (f('description').dirty || f('description').touched || clickedSubmit))}">
        <div class="input-title">
          <label class="have-icon"><i class="icon-text"></i> Опис</label>
          <span>Обов’язково</span>
        </div>
        <div class="input-wrapper">
          <textarea formControlName="description"></textarea>
          <div class="placeholder" *ngIf="!f('description').value">ТЕКСТ!</div>
        </div>
      </div>
      <div class="file-container" [ngClass]="{error: !selectedFile && clickedSubmit}">
        <input type="file" accept="application/pdf" (input)="selectFile($event?.target?.files)">
        <div class="file-text-wrapper">
          <p class="text-title" *ngIf="!selectedFile;else fileSelected">Перетягніть файл або <span>шукайте тут</span></p>
          <ng-template #fileSelected>
            {{selectedFile?.name}}
          </ng-template>
          <!-- <p class="text-blank">підтримує pdf</p> -->
        </div>
      </div>
    </div>
    <div class="buttons-container">
      <button
        class="button-cancel"
        (click)="dialogRef.close()"
      >
        Скасувати
      </button>

      <button
        class="button-send"
        (click)="onSubmit()"
      >
        Завантажити дайджест
      </button>
    </div>
  </div>
</div>