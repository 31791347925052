import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdjudicationService {

  constructor(private http: HttpClient) { }

  DOC_URL = `${environment.searchUrl}/documents`;
  DEC_URL = `${environment.tracerUrl}/documents`;

  getDocumentsById(id) {
    return this.http.get(`${this.DOC_URL}/${id}`);
  }

  getDocById(id) {
    return this.http.get(`${this.DOC_URL}?docId=${id}?groups[]=supreme`);
  }

  getDecisionById(id) {
    return this.http.get(`${this.DEC_URL}?docId=${id}`);
  }

  getDecisionPreview(id) {
    return this.http.get(`${this.DEC_URL}/preview/${id}`, { responseType: 'blob' });
  }

  getLegalPositionByTitle(title, docId?) {
    if (docId) {
      return this.http.post(`${environment.searchUrl}/elasticSearch/combined?instance=1`, { docId })
      .pipe(map((resp: any) => resp?.data?.result));
    }
    return this.http.get(`${environment.searchUrl}/legal_positions?title=${title}`).pipe(map(res => {
      return res['hydra:member']
    }))
  }

  postDocuments(doc) {
    return this.http.post(`${this.DOC_URL}`, doc);
  }

  putDocuments(id, doc) {
    return this.http.patch(`${this.DOC_URL}/${id}`, doc);
  }

  deleteDocument(id) {
    return this.http.delete(`${this.DOC_URL}/${id}`);
  }
}
