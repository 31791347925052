import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-date-period',
  templateUrl: './date-period.component.html',
  styleUrls: ['./date-period.component.scss']
})
export class DatePeriodComponent implements OnInit {

  @Input() titleDateFilter;
  @Input() dateFrom;
  @Input() dateTo;

  @Output() changeDates = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  
  clearDates() {
    this.dateFrom = '';
    this.dateTo = '';
    this.changeDates.emit({
      dateFrom: this.dateFrom,
      dateTo: this.dateTo
    })
  }

  changeDate() {
    setTimeout(() => {
      this.changeDates.emit({
        dateFrom: this.dateFrom,
        dateTo: this.dateTo
      }) 
    });
  }
}
