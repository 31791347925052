<footer class="footer footer-public" *ngIf="!isAuth">
  <div class="left-container">
    <div class="text-footer-ldp">
      <span>
        Цей сайт профінансовано Європейським Союзом і створено за підтримки проєкту ЄС «Право-Justice». Зміст сайту необов’язково відображає позицію Європейського Союзу.
      </span>
    </div>
    <p class="links-container">
      <a (click)="openWarningModal()">
        Застереження щодо Бази правових позицій
      </a>
      <a (click)="openSupportModal()">
        Технічна підтримка
      </a>
      <a href="https://drive.google.com/file/d/1ibUAxcKT-_rsVNSvRuSBN4a4K3R5TrRd/view?usp=sharing" target="_blank" rel="noopener noreferrer">
        Інструкція з використання
      </a>
    </p>
  </div>
  <div class="right-container">
    <a
      href="https://supreme.court.gov.ua/"
      class="design-container"
      target="_blank"
      rel="noopener noreferrer"
    >
      <span>© Copyright, Верховний Суд, 2021, All Rights Reserved</span>
    </a>
  </div>
</footer>

<footer class="footer footer-private" *ngIf="isAuth">
  <div class="left-container">
    <img class="logo" src="../../../../assets/images/logo.svg" alt="" />
    <div class="links-container">
        <a (click)="openSupportModal()">
          Технічна підтримка
        </a>
      <a href="https://drive.google.com/file/d/1ibUAxcKT-_rsVNSvRuSBN4a4K3R5TrRd/view?usp=sharing" target="_blank" rel="noopener noreferrer">
        Інструкція з використання
      </a>
    </div>

  </div>
  <div class="right-container">
    <div class="text-footer-ldp">
      <span>
        Цей сайт профінансовано Європейським Союзом і створено за підтримки проєкту ЄС «Право-Justice». Зміст сайту необов’язково відображає позицію Європейського Союзу.
      </span>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="links-container">
      <a (click)="openWarningModal()">
        Застереження щодо Бази правових позицій
      </a>
    </div>
    <div class="copyright-block">
      <span>
        © Copyright, Верховний Суд, 2021, All Rights Reserved
      </span>
    </div>
  </div>
</footer>
