import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IRegisterData } from 'src/app/models/register';



@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(private authService: AuthService) { }
  registerForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    middleName: new FormControl(''),
    email: new FormControl('', [Validators.required]),
  });
  showPass = false;
  bedRequest = false;


  @ViewChild('email') email;
  sendToEmail = false;
  duplicateEmail = false;
  visiblePass() {
    this.showPass = !this.showPass;
  }
  f(control) { return this.registerForm.get(control); }

  validateF(control) {
    return this.f(control).invalid && (this.f(control).dirty || this.f(control).touched);
  }
  ngOnInit(): void {

  }
  onSubmit() {
    if (this.registerForm.valid) {
      const registerData: IRegisterData = {...this.registerForm.value};

      this.authService.register(registerData).subscribe(res => {
        if (res?.code === 401) {
          this.bedRequest = true;
        } else if (res.status === 422) {
          this.duplicateEmail = true;
        } else {
          this.sendToEmail = true;
        }
      });
    }
  }
}
