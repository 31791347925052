import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Observable } from 'rxjs';
import { Helpers } from 'src/app/helpers/helpers';
import { ResponsePagination } from 'src/app/models/response';
import { IDocuments, IDocumentsFilters } from '../../models/documents';
import { ICourt } from '../../models/court';
import { CustomDateAdapter } from '../../helpers/custom-date-adapter';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CourtDecisionService {

  private BASE_URL = `${environment.searchUrl}/documents`;

  constructor(private http: HttpClient, private authService: AuthService) {}

  get(currentPage = environment.defaultPage, itemsPerPage = environment.defaultItemsCount, filter: IDocumentsFilters = null): Observable<ResponsePagination<IDocuments>> {
    const filters = _.clone(filter);
    delete filters['dateFrom'];
    delete filters['dateTo'];
    let params = new HttpParams().set('partial', 'false');
    if (currentPage && itemsPerPage) {
      params = params.set('page', currentPage.toString()).set('itemsPerPage', itemsPerPage.toString());
    }
    if (filters['adjudicationDate[after]'] || filters['adjudicationDate[before]']) {
      filters['adjudicationDate[after]'] = CustomDateAdapter.getDateToNetworkFormat(filters['adjudicationDate[after]']);
      filters['adjudicationDate[before]'] = CustomDateAdapter.getDateToNetworkFormat(filters['adjudicationDate[before]']);
    }
    if (filters && !filters?.courtCodes?.length) {
      delete filters.courtCodes;
      params = Helpers.populateFilters(params, filters);
    }
    if (this.authService.isAuthenticated()) {
      if (filters?.courtCodes?.length) {
        const courtCodes = filters.courtCodes;
        delete filters.courtCodes;
        return this.http.get<ResponsePagination<IDocuments>>(`${this.BASE_URL}?${Helpers.populateFilters(params, filters).toString()}&${this.buildParamsCourtCodes(courtCodes)}`);
      } else {
        return this.http.get<ResponsePagination<IDocuments>>(this.BASE_URL, { params });
      }
    } else {
      return new Observable<ResponsePagination<IDocuments>>();
    }
  }

  buildParamsCourtCodes(courtCodes) {
    let params = '';
    for (const item of courtCodes) {
      params += `court.courtCode[]=${item}&`;
    }
    return params;
  }

  getLegalPositionById(id) {
    return this.http.get(`${this.BASE_URL}/${id}`);
  }

  editLegalPosition(id, data) {
    return this.http.patch(`${this.BASE_URL}/${id}`, data);
  }


  getCourts() {
    return this.http.get<ResponsePagination<ICourt>>(`${environment.searchUrl}/courts`);
  }
}
