<div class="auth-block" *ngIf="!sendToEmail">
  <div class="title-container">
    <h2>Створіть акаунт</h2>
    <p>Створіть акаунт для швидкого пошуку за правовими позиціями</p>
  </div>
  <div class="form-container" [formGroup]="registerForm">
    <div class="inputs">
      <div class="input-column">
        <div class="input-container"
        [ngClass]="{
          error: (f('firstName').invalid && (f('firstName').dirty && f('firstName').touched)),
          'placeholder-disabled': f('firstName').value}">
          <input type="text" formControlName="firstName">
          <div class="placeholder">Введіть і'мя</div>
        </div>
        <div aria-hidden="true" class="rah-static rah-static--height-zero" style="height: 0px; overflow: hidden;">
          <div style="display: none;">
            <p class="error-text"></p>
          </div>
        </div>
        <div class="input-container"
        [ngClass]="{
          error: (f('lastName').invalid && (f('lastName').dirty && f('lastName').touched)),
          'placeholder-disabled': f('lastName').value}">
          <input type="text" formControlName="lastName">
          <div class="placeholder">Введіть прізвище</div>
        </div>
        <div aria-hidden="true" class="rah-static rah-static--height-zero" style="height: 0px; overflow: hidden;">
          <div style="display: none;">
            <p class="error-text"></p>
          </div>
        </div>
      </div>
      <div class="input-container with-icon"
      [ngClass]="{
        error: (f('email').invalid && (f('email').dirty && f('email').touched)),
        'placeholder-disabled': f('email').value}">
        <input type="text" formControlName="email" (click)="duplicateEmail = false">
        <div class="icons-container">
          <div class="icon"><i class="icon-email"></i></div>
        </div>
        <div class="placeholder">Введіть email</div>
      </div>
      <div aria-hidden="true" class="error-container" [ngClass]="{'show': (f('email').invalid && (f('email').dirty && f('email').touched))}">
        <div >
          <p class="error-text">
            Неправильний email. Будь ласка, спробуйте інший
          </p>
        </div>
      </div>
      <div aria-hidden="true" class="error-container" [ngClass]="{'show': duplicateEmail}">
        <div >
          <p class="error-text">
            Цей Email вже використовується. Спробуйте інший
          </p>
        </div>
      </div>
    </div><button class="button-submit" (click)="onSubmit()">Створити акаунт<i class="icon-back"></i></button>
    <div class="links">
      <p></p><a routerLink="/login">Увійти до акаунту</a>
    </div>
  </div>
</div>

  <div *ngIf="sendToEmail" class="auth-block authswitchfade-enter-done">
    <div class="title-container">
      <h2>Посилання надіслано на пошту!</h2>
      <p>Будь ласка, перевірте свою поштову скриньку й обов’язково продивіться папки «Вхідні» або «Спам»</p>
    </div>
    <div class="form-container">
      <button class="button-submit single-button" routerLink="/">
        Повернутися до входу
        <i class="icon-back"></i>
      </button>
    </div>
  </div>
