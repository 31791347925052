import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-restore',
  templateUrl: './restore.component.html',
  styleUrls: ['./restore.component.scss']
})
export class RestoreComponent implements OnInit {


  constructor(private authService: AuthService) { }
  restoreForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
  });
  request;
  ngOnInit(): void {
  }
  onSubmit() {
    if (this.restoreForm.valid) {
      this.authService.restore(this.restoreForm.value).subscribe(res => {
        this.request = res;
      });
    }
  }
  f(control) { return this.restoreForm.get(control); }

}
