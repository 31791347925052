import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

/** Adapts the native JS Date for use with cdk-based components that work with dates. */
@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  static get currentDateFormat() {
    return this.dateFormat;
  }

  static get currentDateTimeFormat() {
    return this.dateTimeFormat;
  }
  static dateFormat = 'dd.MM.yyyy';
  static dateFilterFormat = 'YYYY-MM-DD';
  static dateTimeFormat = 'dd.MM.yyyy HH:mm';
  static startDate = new Date('1970');


  static getDateToNetworkFormat(date) {
    if (!date) {
      return '';
    }
    const timestamp = Date.parse(date);
    if (isNaN(timestamp)) {
      date = new Date(date.split('.').reverse().join('-'));
    } else {
      date = new Date(date);
    }
    const curr_date = date.getDate();
    const curr_month = date.getMonth() + 1;
    const curr_year = date.getFullYear();
     
    return curr_year + "-" + curr_month + "-" + curr_date;
  }

}
