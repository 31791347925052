import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Helpers } from '../../../helpers/helpers';

@Component({
  selector: 'app-adjudication-item',
  templateUrl: './adjudication-item.component.html',
  styleUrls: ['./adjudication-item.component.scss']
})
export class AdjudicationItemComponent {


  @Input() visibleAttach = true;
  @Input() data;
  @Input() documentEchrsShow = false;
  @Output() clickAttachItem = new EventEmitter();
  attach;
  hoveredOnRelatedBtn = false;
  openDescription = false;

  hoverOnRelatedBtn(type) {
    this.hoveredOnRelatedBtn = type === 'move';
  }

  getDesc(text) {
    return Helpers.linkTransform(text.replace(/\n/g, '<br/>'));
  }

  openedDescription(content, parentContent) {
    this.openDescription = !this.openDescription;
    if (content.style.maxHeight) {
      content.style.maxHeight = null;
      parentContent.style.height = 'auto'
    } else {
      content.style.maxHeight = content.scrollHeight + 'px';
      parentContent.style.height = content.scrollHeight + 31 + 'px';
    }
  }

  checkVisibleBtnDesc(el) {
    return el?.offsetHeight > 35;
  }

  attachItem() {
    this.clickAttachItem.emit(this.data.id);
  }
}
