import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-digest-modal',
  templateUrl: './digest-modal.component.html',
  styleUrls: ['./digest-modal.component.scss']
})
export class DigestModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DigestModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  digestForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
  });

  selectedFile;
  clickedSubmit = false;
  ngOnInit(): void {
  }
  f(control) { return this.digestForm.get(control); }
  onSubmit() {
    this.clickedSubmit = true;
    if (this.digestForm.valid && this.selectedFile) {
      this.dialogRef.close({...this.digestForm.value, file: this.selectedFile});
    }
  }

  selectFile(file) {
    this.selectedFile = file && file[0];
  }
}
