import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from 'src/app/auth/services/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Helpers } from 'src/app/helpers/helpers';
import { ResponsePagination } from 'src/app/models/response';
import { LegalPosition } from 'src/app/models/legal-position';
import { ICategories } from 'src/app/models/categories';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private BASE_URL = `${environment.searchUrl}/legal_positions`;

  constructor(private http: HttpClient, private authService: AuthService) {}

  // hydra+
  get(currentPage = environment.defaultPage, itemsPerPage = environment.defaultItemsCount, filters = null): Observable<ResponsePagination<LegalPosition>> {
    let params = new HttpParams().set('partial', 'false');

    if (currentPage && itemsPerPage) {
      params = params.set('page', currentPage.toString()).set('itemsPerPage', itemsPerPage.toString());
    }
    if (filters.status === 'all') {
      delete filters.status;
    }
    if (filters) {
      params = Helpers.populateFilters(params, filters);
    }
    return this.http
    .get<ResponsePagination<LegalPosition>>(this.BASE_URL, { params });
  }

  // hydra+
  getLegalPositionById(id: number): Observable<LegalPosition> {
    return this.http.get<LegalPosition>(`${this.BASE_URL}/${id}`);
  }

  // TODO HYDRA related
  addDocumentToLegalPosition(id, doc) {
    return this.http.patch(`${this.BASE_URL}/${id}`, {documents: doc});
  }

  // TODO HYDRA related
  editLegalPosition(id, data) {
    return this.http.patch(`${this.BASE_URL}/${id}`, data);
  }

  // Тільки правові позиції = true - /elasticSearch/legalPosition
// https://reyestr.court.gov.ua/Review/100324764/
  getLegalPositionBySearch(search, onlyLegalPos, status?, categories?) {
    const statusParams = status && status !== 'all' ? `&status=${status}` : '';
    if (typeof search === 'string' && !onlyLegalPos) {
      if (!search && categories.length) {
        return this.getLegalPositionByCategories(categories, status);
      }
      const documentId = Helpers.checkLink(search) && (search.split('/').length && search.split('/')[search.split('/').length - 1] || search.split('/')[search.split('/').length - 2]);
      return this.http.post(`${environment.searchUrl}/elasticSearch/combined?instance=1${statusParams}${categories ? this.buildParamsCategoriesIds(categories) : ''}`, (documentId ? {docId: documentId}: {text: search}))
      .pipe(catchError(el => {
        return new BehaviorSubject({data: {result:[], success: false}});
      }
      ));
    } else if(onlyLegalPos) {
      const documentId = Helpers.checkLink(search) && (search.split('/').length && search.split('/')[search.split('/').length - 1] || search.split('/')[search.split('/').length - 2]);
      return this.http.post(`${environment.searchUrl}/elasticSearch/legalPosition`, (documentId ? {docId: documentId}: {text: search}) );
    } else {
      const formData = new FormData();
      formData.append('file', search);
      return this.http.post(`${environment.searchUrl}/elasticSearch/file?instance=1${statusParams}`, formData);
    }
  }

  // hydra+
  getLegalPositionByCategories(categories, status) {
    const statusParams = status && status !== 'all' ? `&status=${status}` : '';
    return this.http.get<LegalPosition[]>(`${environment.searchUrl}/legal_positions?${categories ? this.buildParamsCategoriesIds(categories) : ''}${statusParams}`).pipe(map(res => {
      return res['hydra:member'];
    }))
  }

  buildParamsCategoriesIds(categories) {
    let params = '';
    for (const item of categories) {
      params+= `&categories.id[]=${item}`
    }
    return params;
  }


  deleteLegalPosition(id) {
    return this.http.delete(`${this.BASE_URL}/${id}`);
  }

  // Request URL: http://159.69.199.145/elasticSearch/combined?instance=1

  public currentCategories: ICategories[] = null;
  onCategories: EventEmitter<ICategories[]> = new EventEmitter();

  public setCategories(categories) {
    this.currentCategories = categories;
    this.onCategories.emit(this.currentCategories);
  }

  // hydra+
  getCategories() {
    if (!this.currentCategories) {
      return this.http.get<ResponsePagination<ICategories>>(`${environment.searchUrl}/categories?exists[parent]=false`).pipe(map(resp => {
        this.setCategories(resp['hydra:member'].map(item => {
          item.opened = false;
          return item;
        }));
        return resp;
      })).subscribe();
    }

  }


  // TODO CHECK ADMIN
  postCategories(data) {
    return this.http.post(`${environment.searchUrl}/categories`, data);
  }

  deleteCategories(id) {
    return this.http.delete(`${environment.searchUrl}/categories/${id}`);
  }
  putCategories(data) {
    return this.http.patch(`${environment.searchUrl}/categories/${data.id}`, data);
  }
}
