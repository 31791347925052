import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-card-panel-back',
  templateUrl: './card-panel-back.component.html',
  styleUrls: ['./card-panel-back.component.scss']
})
export class CardPanelBackComponent implements OnInit {

  constructor(public location: Location) { }

  ngOnInit(): void {
  }

}
