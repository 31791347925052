import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './auth/services/auth.service';
import { Router } from '@angular/router';
import { HeaderComponent } from './modules/layout/header/header.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AuthService]
})
export class AppComponent implements OnInit{
  currentUser;
  sidebarVisible = false;
  @ViewChild('header') appHeader: HeaderComponent;
  constructor(
    public authService: AuthService,
    private router: Router,
    ) {
    this.authService.refreshToken().subscribe();
  }
  ngOnInit() {
    this.currentUser = this.authService.currentUser$;
    setInterval(()=> {
      if(!this.authService.isAuthenticated()) {
        if(!(this.router.url.includes('restore') || this.router.url.includes('register') || this.router.url.includes('create-password'))) {
          // this.router.navigateByUrl('/login')
        }
      }
    }, 1000)
  }

  checkAuthPage() {
    if(!(this.router.url.includes('login') || this.router.url.includes('restore') || this.router.url.includes('register') || this.router.url.includes('create-password'))) {
      return true;
    }
    return false;
  }

  checkActiveLink(): boolean {
    return this.router.routerState.snapshot.root.children[0]?.children[0]?.data.hideFooter;
  }

  toggleSidebar() {
    this.sidebarVisible = !this.sidebarVisible
    this.appHeader.sidebarVisible = this.sidebarVisible;
  }
}
