<div class="create-adjudication-modal-block">
  <div class="create-adjudication-modal">
    <button class="close-modal" (click)="dialogRef.close()">
      <i class="icon-cross"></i>
    </button>
    <div class="top-container">
      <h2>Додати судове рішення</h2><i class="icon-document-double"></i>
    </div>
    <div class="form-container">
      <div class="adjudication-form-wrapper">
        <div
        class="input-container"
        [ngClass]="{
          'placeholder-disabled': adUrl,
        error: error,
        'error-unique': error === 'courtLinkUnique'
      }"
      >
        <div class="title-container">
          <label>
            <i class="icon-attached"></i>
            Посилання на судове рішення
          </label>
          <span>Обов’язково</span>
        </div>
        <div class="input-wrapper">
          <input type="text"
          [(ngModel)]="adUrl"
          (paste)="getDocById()"
          (input)="getDocById()"
          />
          <div class="placeholder">https://reyestr.court.gov.ua/...</div>
        </div>
        <div class="error-container" [ngClass]="{'show': error}">
          <div class="error-text">
            <div class="error-message-wrapper" *ngIf="error === 'courtLinkUnique'; else errorMsg">
              <div class="error-wrapper">
                Це судове рішення вже додано до системи. Будь ласка,
                <a routerLink="/adjudication/{{findDoc?.id}}" (click)="this.dialogRef.close()">перегляньте його тут.</a>
              </div>
              <a routerLink="/adjudication/{{findDoc?.id}}" target="_blank" rel="noopener noreferrer">
                <i class="icon-open-external"></i>
              </a>
            </div>
            <ng-template #errorMsg>
              <span>{{errorMsgText}}</span>
            </ng-template>
          </div>
        </div>
      </div>
        <div class="input-container"
        [ngClass]="{disabled: error === 'error'}"
        >
        <div class="title-container">
          <label>
            <i class="icon-attached"></i>
            Введіть назву судового рішення
          </label>
          <span>Обов’язково {{textareaText.length}}</span>
        </div>
        <div class="input-wrapper">
          <textarea [value]="textareaText" (input)="textareaText = $event.target.value" [disabled]="(error || !adUrl)"></textarea>
          <div class="placeholder" *ngIf="!textareaText">Назва судового рішення</div>
        </div>
        <div class="error-container" [ngClass]="{'show': textareaText.length > 255}">
          <div>
            <p class="error-text">
              Назва не має перевищувати 255 символів
            </p>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="buttons-container">
      <button class="button-cancel" (click)="dialogRef.close()">Скасувати</button>
      <button class="button-send" (click)="onSubmit()">Завантажити</button>
      </div>
  </div>
</div>
