import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Roles, checkRole } from './check-role';

@Directive({
  selector: '[appCheckRole]'
})
export class CheckRoleDirective {
  private roles: Roles;
  private viewRef: EmbeddedViewRef<any> | null = null;

  @Input()
  set appCheckRole(roles: Roles) {
    this.roles = roles;
    this.viewRef = null;
    this.check();
  }

  constructor(private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {}

  check() {

    const isAllowed = checkRole(this.roles);
    if (isAllowed) {
      this.viewRef = this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
    }
  }
}
