import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-decision-yespl-list-item',
  templateUrl: './decision-yespl-list-item.component.html',
  styleUrls: ['./decision-yespl-list-item.component.scss']
})
export class DecisionYesplListItemComponent implements OnInit {

  constructor() { }
  @Input() data;
  @Input() docs;
  @Input() search;
  active = false;
  ngOnInit(): void {
  }

  setActive(active) {
    this.active = active;
  }
}
