<div class="default-modal-template-block center">
  <div
    class="background-toggle"
    (click)="dialogRef.close()"
  ></div>
  <div class="background-blur" (click)="dialogRef.close()"></div>
  <div class="default-modal-template support-modal">
    <button
      class="close-modal"
      (click)="dialogRef.close()"
    >
      <i class="icon-cross-new"></i>
    </button>

    <div class="container-support">

      <div class="title">
        <span class="icon-phone-new"></span>
        <span class="text">Технічна підтримка</span>
      </div>
      <div class="info">
        <div class="info-item">
          <div class="title-info">
            <span class="icon-mail"></span>
            <span class="text">Email-підтримка</span>
          </div>
          <div class="sub-title">
            <span>
              Щоб отримати онлайн-допомогу, надішліть лист на вказану електронну адресу:
            </span>
          </div>
          <div class="links">
            <a href="mailto:inbox@lpd.court.gov.ua">inbox@lpd.court.gov.ua</a>
          </div>
        </div>
      </div>
    </div>
</div>
