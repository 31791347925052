<section class="search-filter-block">

  <h4>
    <span class="label">
      Фільтр
      <i class="icon-setting-categories"></i>
    </span>
    <div class="cansel" *ngIf="selectedIds?.length || dates?.dateFrom || dates?.dateTo" (click)="clearFilters()">
      Скинути
    </div>
  </h4>

  <div class="filter-list">
    <div class="title-list">
      <span>{{titleFilter}}</span>
    </div>
    <div class="items">
      <div class="item" *ngFor="let el of list">
        <input type="checkbox" class="hide-checkbox" id="{{el.courtCode}}courts" (change)="selectEl($event, el)" [checked]="findCheckedItem(el)">
        <label for="{{el.courtCode}}courts">{{el.name}} </label>
      </div>
    </div>
  </div>
  <app-date-period
  [titleDateFilter]="titleDateFilter"
  (changeDates)="changeDates($event)"
  [dateFrom]="filters?.dateFrom"
  [dateTo]="filters?.dateTo"
  ></app-date-period>
</section>


