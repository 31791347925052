import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotificationsService } from '../../modules/common-components/notification-local/notifications.service';
import { UsersService } from '../../modules/profile/users/users.service';
import { ApproveModalComponent } from '../approve-modal/approve-modal.component';

@Component({
  selector: 'app-user-details-modal',
  templateUrl: './user-details-modal.component.html',
  styleUrls: ['./user-details-modal.component.scss']
})
export class UserDetailsModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UserDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private notiService: NotificationsService,
    private userService: UsersService) { }


    userDetailsForm = new FormGroup({
      person: new FormGroup({
        firstName: new FormControl(this.data?.user.person.firstName, [Validators.required]),
        lastName: new FormControl(this.data?.user.person.lastName, [Validators.required]),
        middleName: new FormControl(this.data?.user.person.middleName),
        id: new FormControl(this.data?.user.person.id),
      }),
      email: new FormControl(this.data?.user.email, [Validators.required]),
      type: new FormControl(this.data?.user.type),
    });

  ngOnInit(): void {
  }
  f(control) { return this.userDetailsForm.get(control); }


  onSubmit() {
    if (this.userDetailsForm.valid) {
      if (this.data.user.type === 'admin') {
        this.userDetailsForm.value.type = 'admin';
      } else {
        this.userDetailsForm.value.type = this.userDetailsForm.value.type ? this.userDetailsForm.value.type : 'user';
      }
      this.dialogRef.close(this.userDetailsForm.value);
    }
  }

  removeUser() {
    this.dialog.open(ApproveModalComponent, {
      data: {
        title: 'Ви дійсно бажаєте видалити користувача?',
        description: 'Після видалення, він втратить доступ до системи та користувача потрібно буде запрошувати повторно',
        canselBtnTxt: 'Скасувати',
        saveBtnTxt: 'Видалити'
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.userService.removeUser(this.data.user.id).subscribe(() => {
          this.notiService.show(false, 'Користувача видалено');
          this.dialogRef.close('remove');
        });
      }
    })
  }

  checkSelected(type) {
    if (this.userDetailsForm.get('type').value === type) {
      this.userDetailsForm.get('type').setValue('');
    } else {
      this.userDetailsForm.get('type').setValue(type);
    }
  }
}
