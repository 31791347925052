import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AuthGuardService } from './auth/helpers/auth-guard.service';
import { JwtInterceptorService } from './auth/helpers/jwt.interceptor';
import { LoginGuardService } from './auth/helpers/login-guard.service';
import { HelpersModule } from './helpers/helpers.module';
import { RoutesService } from './helpers/routes.service';
import { ClassifierSettingsModalComponent } from './modals/classifier-settings-modal/classifier-settings-modal.component';
import { CourtCaseModalComponent } from './modals/court-case-modal/court-case-modal.component';
import { DigestModalComponent } from './modals/digest-modal/digest-modal.component';
import { LegalPositionModalComponent } from './modals/legal-position-modal/legal-position-modal.component';
import { SupportModalComponent } from './modals/support-modal/support-modal.component';
import { UserDetailsModalComponent } from './modals/user-details-modal/user-details-modal.component';
import { WarningModalComponent } from './modals/warning-modal/warning-modal.component';
import { CommonComponentsModule } from './modules/common-components/common-components.module';
import { LayoutModule } from './modules/layout/layout.module';
import { CourtDecisionService } from './modules/new-court-decisions/court-decisions.service';
import { ApproveModalComponent } from './modals/approve-modal/approve-modal.component';
import { CheckSimilarityLegalModalComponent } from './modals/check-similarity-legal-modal/check-similarity-legal-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    UserDetailsModalComponent,
    LegalPositionModalComponent,
    CourtCaseModalComponent,
    DigestModalComponent,
    ClassifierSettingsModalComponent,
    WarningModalComponent,
    SupportModalComponent,
    ApproveModalComponent,
    CheckSimilarityLegalModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    HttpClientModule,
    AuthModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HelpersModule,
    CommonComponentsModule,
    FormsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
    AuthGuardService,
    LoginGuardService,
    RoutesService,
    CourtDecisionService
  ],
  entryComponents: [
    ClassifierSettingsModalComponent,
    LegalPositionModalComponent,
    ApproveModalComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
