<section class="search-list-block" [ngClass]="customClass">
  <h4>
    {{search
      ? "Результати пошуку: "
      : label}}
  </h4>

      <div *ngIf="searchResponse && searchResponse?.['hydra:member']" class="list-items-template">
        <ng-container *ngIf="typeData === 'legal_positions'">
          <app-list-item
          *ngFor="let element of searchResponse?.['hydra:member']"
          [data]="element"
          [search]="search"></app-list-item>
        </ng-container>
        <ng-container *ngIf="typeData === 'court_decisions'">
          <app-decision-list-item
          *ngFor="let element of searchResponse?.['hydra:member']"
          [data]="element"
          [search]="search"
          ></app-decision-list-item>
        </ng-container>
        <ng-container *ngIf="typeData === 'decision-yespl'">
          <app-decision-yespl-list-item
          *ngFor="let element of searchResponse?.['hydra:member']"
          [data]="element"
          [search]="search"
          ></app-decision-yespl-list-item>
        </ng-container>

      </div>
      <app-paginator
      *ngIf="searchResponse?.pagination?.countItems"
      [currentPage]="searchResponse?.pagination?.currentPage"
      [lastPage]="searchResponse?.pagination?.lastPage"
      [itemsPerPage]="searchResponse?.pagination?.itemsPerPage"
      (ItemsPerPage)="setPerPage($event)"
      (ToPage)="toPage($event)"></app-paginator>

</section>
