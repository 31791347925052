<section class="adjudication-list-block">
  <div class="list-title">
    <div class="title-inner">
      <h4>
        <span *ngIf="!documentEchrsShow">Правові позиції:</span>
        <span *ngIf="documentEchrsShow">Рішення ЄСПЛ:</span>
        </h4>
      <div class="control-new">
        <ng-container *ngIf="!documentEchrsShow">
          <button (click)="setModal()" *appCheckRole="['operator', 'admin', 'operator_high']">
            <span>Завантажити позицію</span>
            <i class="icon-cross"></i>
          </button>
        </ng-container>

        <ng-container *ngIf="documentEchrsShow">
          <button [routerLink]="['/decision-yespl/card']"  *appCheckRole="['operator', 'admin', 'operator_high']">
            <span>Завантажити рішення ЄСПЛ</span>
            <i class="icon-cross"></i>
          </button>
        </ng-container>
      </div>
    </div>
    <div class="by-name-filter" *ngIf="isAuth">
      <span class="icon-search"></span>
      <input type="text" [placeholder]="documentEchrsShow ? 'Знайти рішення ЄСПЛ…' : 'Знайти правову позицію…'" [(ngModel)]="searchText" (ngModelChange)="inputSearch()">
    </div>

  </div>

  <div class="adjudication-list-wrapper">
    <app-loader *ngIf="showSpinner"></app-loader>

    <div class="list-items-template" *ngIf="!(!searchListLegalPosition?.length && !!searchText)">
      <ng-container *ngFor="let item of searchListLegalPosition?.length ? searchListLegalPosition : attachListLegalPosition">
        <app-adjudication-item
        [visibleAttach]="isAuth"
        [data]="item"
        [documentEchrsShow]="documentEchrsShow"
        (clickAttachItem)="onClickAttachItem($event)"
        ></app-adjudication-item>
      </ng-container>
    </div>

    <ng-container *appCheckRole="['operator', 'admin', 'operator_high']">
      <div class="add-new-container" *ngIf="!searchListLegalPosition?.length && !!searchText">
        <div class="inner-info">
          <div class="icon-container">
            <img src="../../../../assets/images/document.svg" width="86px" alt=""/>
          </div>
          <p>Релевантної правової позиції не знайдено.</p>
          <p>Натисніть на “Завантажити позицію +”</p>
        </div>
      </div>
    </ng-container>
    <div class="gradient-bottom-list"></div>
  </div>
</section>
