import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements OnInit {

  constructor() { }
  @Input() data;
  @Input() docs;
  @Input() search;
  active = false;
  ngOnInit(): void {
  }

  setActive(active) {
    this.active = active;
  }
}
