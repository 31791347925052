import {throwError as observableThrowError,  Observable ,  BehaviorSubject } from 'rxjs';

import {take, filter, catchError, switchMap, finalize} from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpResponse,
    HttpUserEvent,
    HttpErrorResponse,
    HttpEvent } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';
import { NotificationsService } from '../../modules/common-components/notification-local/notifications.service';


@Injectable()
export class JwtInterceptorService implements HttpInterceptor {

    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private requests = [];

    constructor(private injector: Injector, private notiService: NotificationsService) {}

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        const authService = this.injector.get(AuthService);
        const checkTraceUrl = req.url.includes(environment.tracerUrl);
        if (req.url.includes(environment.searchUrl)) {
          req = req.clone({ setHeaders: { Accept: 'application/ld+json' }});
          if (req.method === 'POST') {
            if (!(req.body instanceof FormData)) {
              req = req.clone({ setHeaders: { 'Content-Type': 'application/ld+json;' }});
            }
          }
          if (req.method === 'PATCH') {
            req = req.clone({ setHeaders: { 'Content-Type': 'application/merge-patch+json' }});
          }
        }

        if (authService.getAuthToken() && req.url !== `${environment.searchUrl}/jwt/refresh` && !checkTraceUrl) {
            return req.clone({ setHeaders: { Authorization: 'Bearer ' + token }});
        } else {
            return req.clone();
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler)
    : Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | HttpEvent<any>> {
        const authService = this.injector.get(AuthService);

        return next.handle(this.addToken(req, authService.getAuthToken())).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse) {
                    switch ((error as HttpErrorResponse).status) {
                        case 400:
                            return this.handle400Error(error);
                        case 401:
                            if ((req.url === `${environment.searchUrl}/jwt/refresh`)) {
                                this.logoutUser();
                            } else if (authService.getAuthToken()) {
                                return this.handle401Error(req, next);
                            } else {
                                return next.handle(req);
                            }
                            break;
                        case 422:
                            if (error.error['detail'] === 'caseNum: Це значення вже використовується.')
                                this.notiService.show('new-error', 'Неможливо зберігти', 'Такий номер справи вже є в базі. Напишіть інший номер справи.');
                            return observableThrowError(error);
                        default:
                            return observableThrowError(error);
                    }
                } else {
                    return observableThrowError(error);
                }
            }));
    }

    handle400Error(error) {
        if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
            // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
            return this.logoutUser();
        }

        return observableThrowError(error);
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        this.requests.push(req);
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            const authService = this.injector.get(AuthService);

            return authService.refreshToken().pipe(
                switchMap((resp) => {
                    if (resp) {
                        this.tokenSubject.next(resp.token);
                        return next.handle(this.addToken(req, resp.token));
                    }

                    // If we don't get a new token, we are in trouble so logout.
                    return this.logoutUser();
                }),
                catchError(error => {
                    // If there is an exception calling 'refreshToken', bad news so logout.
                    return this.logoutUser();
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                }), );
        } else {
            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {

                    return next.handle(this.addToken(req, token));
                }), );
        }
    }

    logoutUser() {
        // Route to the login page (implementation up to you)
        localStorage.removeItem('auth');
        return observableThrowError('401');
    }
}
