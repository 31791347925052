import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClassifierSettingsModalComponent } from '../classifier-settings-modal/classifier-settings-modal.component';
import { CheckSimilarityLegalModalComponent } from '../check-similarity-legal-modal/check-similarity-legal-modal.component';
import { SearchService } from '../../modules/search/search.service';

@Component({
  selector: 'app-legal-position-modal',
  templateUrl: './legal-position-modal.component.html',
  styleUrls: ['./legal-position-modal.component.scss']
})
export class LegalPositionModalComponent implements OnInit {

  approveSaveData = false;

  constructor(
    public dialogRef: MatDialogRef<LegalPositionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialog,
    private searchService: SearchService) { }

  legalPossForm = new FormGroup({
    title: new FormControl('', [Validators.required, Validators.maxLength(255)]),
    text: new FormControl('', [Validators.required]),
  });

  f(control) {
    return this.legalPossForm.get(control);
  }

  ngOnInit(): void {
    if (this.data?.legalPosition) {
      this.legalPossForm.patchValue({ ...this.data?.legalPosition });
      this.selectedCategories = this.data?.legalPosition.categories;
    }
  }


  onSubmit() {
    if (this.legalPossForm.valid) {
      this.checkSimilarity();
    }
  }

  saveData() {
    this.checkedSimilarityLegal = false;
    if (this.approveSaveData) {
      this.dialogRef.close({ ...this.legalPossForm.value, categories: this.selectedCategories.map(el => el['@id']) });
    }
  }

  selectedCategories = [];// [{id: 9, title: 'allala', parentId: 1}];
  selectedCategoriesIds = [];

  attachCategories() {
    const dialogClassSett = this.dialog.open(ClassifierSettingsModalComponent, {
      data: {
        selectedCategories: this.selectedCategories,
        showFooterBtn: true
      }
    });
    dialogClassSett.afterClosed().subscribe(res => {
      if (res) {
        this.selectedCategoriesIds = res.selectedCategoriesIds;
        this.selectedCategories = res.selectedCategories;
      }
    })
  }

  checkedSimilarityLegal = false;

  checkSimilarity() {
    this.checkedSimilarityLegal = true;
    this.searchService.getLegalPositionBySearch(this.f('title').value, true).subscribe((resp: any) => {
      if (resp.data.result.length) {
        this.dialog.open(CheckSimilarityLegalModalComponent, {
          data: {
            list: resp.data.result.splice(0, 5),
            search: this.f('title').value
          }
        }).afterClosed().subscribe(res => {
          this.approveSaveData = res;
          this.saveData();
        })
      } else {
        this.approveSaveData = true;
        this.saveData();
      };
    });
  }

}
