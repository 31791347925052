<div class="list-item adjudication-list-item" data-id="{{data.id}}">

  <div class="list-inner">
    <div class="icon-container">
      <ng-container *ngIf="!documentEchrsShow">
        <img src="../../../../assets/images/document.svg" alt=""/>
      </ng-container>
      <ng-container  *ngIf="documentEchrsShow">
        <img *ngIf="data?.decidedDate" src="../../../../assets/images/decision-yespl-item.svg" alt="" />
      <img *ngIf="!data?.decidedDate" src="../../../../assets/images/decision-yespl-item-gray.svg" alt="" />
      </ng-container>
    </div>
    <div class="content-container">
      <h5>
        <a
          class="link-item"
          routerLink="{{documentEchrsShow ? '/decision-yespl/card/'+data?.id : '/home/search/' + data.id}}"
          target="_blank"
        >
          {{data.title}}
          <i class="icon-open-external"></i>
        </a>
        <div
          *ngIf="visibleAttach"
          class="related-btn"
          [ngClass]="{hovered: hoveredOnRelatedBtn, newItem: !data.attach, attach: data?.attach}"
          (click)="attachItem()"
          (mousemove)="hoverOnRelatedBtn('move')"
          (mouseleave)="hoverOnRelatedBtn('leave')">
          <button>
            <i class="icon-attached"></i>
            <span> {{(hoveredOnRelatedBtn && data?.attach) ? 'Роз’єднати' : !data.attach ? 'Зв’язок' : 'Зв’язано'}}</span>
          </button>
        </div>
      </h5>
      <div
        #parentContent
        class="text-description"
        [ngClass]="{withBtn: checkVisibleBtnDesc(content)}"
      >
        <p #content class="description-text content">
          <ng-container *ngIf="!documentEchrsShow">{{data.text}}</ng-container>
          <ng-container *ngIf="documentEchrsShow">{{data.description}}</ng-container>
        </p>
        <span
          class="open-description"
          *ngIf="checkVisibleBtnDesc(content)"
          [ngClass]="{opened: openDescription}"
          (click)="openedDescription(content, parentContent)"
        >
        {{!openDescription ? 'Розгорнути...' : 'Згорнути'}}
      </span>
      </div>
    </div>
  </div>
</div>
