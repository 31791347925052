import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdjudicationService } from 'src/app/modules/adjudication/adjudication.service';
import { debounce } from 'lodash';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-court-case-modal',
  templateUrl: './court-case-modal.component.html',
  styleUrls: ['./court-case-modal.component.scss']
})
export class CourtCaseModalComponent {

  constructor(
    public dialogRef: MatDialogRef<CourtCaseModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private adjudicationService: AdjudicationService) { }


  adUrl = '';
  findDoc;
  error;
  errorMsgText = '';
  documentId;
  textareaText = '';

  findDecDoc;

  previewUrl;

  getCheckDoc = debounce(() => {
    this.documentId = this.adUrl.split('/').pop();
    if ((this.documentId + '').length >= 1 && !Number.isInteger(+this.documentId)) {
      this.documentId = false;
    }

    this.textareaText = '';
      this.error = '';
      this.errorMsgText = '';
      if (!this.documentId) {
        this.error = 'error';
        this.errorMsgText = 'Введено некоретне посилання. Будь ласка, спробуйте ще раз або напишіть нам.';
      } else {
        this.adjudicationService.getDocById(this.documentId).subscribe((res: any) => {
          if (res['hydra:member'].length) {
            this.findDoc = res['hydra:member'][0];
            this.error = 'courtLinkUnique';
          } else {
            this.adjudicationService.getDecisionById(this.documentId).subscribe((resDecs: any) => {
              const resDec = resDecs.member;
              if (resDec.length) {
                this.findDecDoc = resDec[0];
                this.textareaText = `${resDec[0].judgmentForm ? resDec[0].judgmentForm.name + ',' : ''} ${resDec[0].adjudicationDate ? new Date(resDec[0].adjudicationDate).toLocaleDateString() + ',' : ''} ${resDec[0].causeNum ? resDec[0].causeNum + ',' : ''} ${resDec[0].causeCategory ? resDec[0].causeCategory.name + ',' : ''} ${resDec[0].court ? resDec[0].court.name : ''}`;
              } else {
                this.errorMsgText = `Судове рішення відсутнє в базі 'Правових позицій'`;
                this.error = 'error';
              }
            });
          }
        });
      }
  }, environment.debounceLoadTime);

  getDocById() {
    this.getCheckDoc();

  }
  onSubmit() {
    if(!this.error && !this.errorMsgText && this.textareaText.length <= 255) {
      this.dialogRef.close({docId: +this.documentId, title: this.textareaText});
    }
  }
}
