import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-court-decisions-breadcrumbs',
  templateUrl: './new-court-decisions-breadcrumbs.component.html',
  styleUrls: ['./new-court-decisions-breadcrumbs.component.scss']
})
export class NewCourtDecisionsBreadcrumbsComponent implements OnInit {

  @Input() namePage;
  constructor() { }

  ngOnInit(): void {
  }

}
