import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-decision-list-item',
  templateUrl: './decision-list-item.component.html',
  styleUrls: ['./decision-list-item.component.scss']
})
export class DecisionListItemComponent {

  constructor() { }
  @Input() data;
  @Input() docs;
  @Input() search;
  active = false;

  setActive(active) {
    this.active = active;
  }

}
