<div 
class="separator-block"
[ngClass]="{'legalPosition': legalPosition, 'full-width': fullW}"
[ngStyle]="{'padding': customPadding ? '0 ' + customPadding + 'px' : '0 52px'}"
>
  <div class="separator-inner">
    <div class="icon-container" [ngClass]="{bgW:bgW}">
      <i class="icon-trident"></i>
    </div>
  </div>
</div>