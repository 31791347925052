import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SupportModalComponent } from 'src/app/modals/support-modal/support-modal.component';
import { WarningModalComponent } from 'src/app/modals/warning-modal/warning-modal.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor(private dialog: MatDialog) { }
  @Input() isAuth: boolean;

  openWarningModal() {
    this.dialog.open(WarningModalComponent);
  }

  openSupportModal() {
    this.dialog.open(SupportModalComponent);
  }

}
