<div class="new-court-decisions-breadcrumbs">
  <a routerLink="/">
    Головна сторінка 
  </a>
  <i class="icon-arrow-sharp"></i>
  <a>
    {{namePage}}
  </a>
  
</div>
