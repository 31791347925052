

export class Helpers {
  static populateFilters(params, filters) {
    Object.keys(filters).map(filter => {
      if (filters.hasOwnProperty(filter) && filters[filter]) {
        params = params.set(filter, filters[filter]);
      }
    });
    return params;
  }

  static checkLink(str: string) {
    if (str && str.trim()) {
      return str.match(/(((ftp|https?):\/\/)[\-\wа-я@:%_\+.~#?,&\/\/=]+)/g);
    }
    return '';
  }

  static getUserFullName(user) {
    return `${user?.person.fullName}`;
  }

  static getUserEmail(user) {
    return `${user?.email}`;
  }

  static checkBlank = (value) => {
    return !value.length;
  }

  static checkPassword(value) {
    if (this.checkBlank(value)) { return true; }
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return !re.test(value) ? 'password' : false;
  }

  static linkTransform(str: string) {
    if (str && str.trim()) {
      const urls = str.match(/(((ftp|https?):\/\/)[\-\wа-я@:%_\+.~#?,&\/\/=]+)/g);
      if (urls) {
        urls.forEach(url => {
          str = str.replace(url, '<a class="cropp-link" target="_blank" href="' + url + '">' + url + '</a>');
        });
      }
      return str;
    }
    return '';
  }

}
