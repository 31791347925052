import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RestoreComponent } from './restore/restore.component';
import { RegisterComponent } from './register/register.component';
import { AuthGuardService } from './helpers/auth-guard.service';
import { AuthService } from './services/auth.service';
import { CreatePasswordComponent } from './create-password/create-password.component';


@NgModule({
  declarations: [LoginComponent, RestoreComponent, RegisterComponent, CreatePasswordComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule
  ],
  providers: [
    AuthGuardService,
    {
      provide: AuthService,
      useClass: AuthService
    }
  ]
})
export class AuthModule { }
