<!-- <header class="header header-public" *ngIf="!isAuth">
  <div class="left-container">
    <div class="logo"></div>
  </div>
  <div class="right-container">
    <div class="login-link">
      <a routerLink="/">Увійти до акаунту</a>
    </div>
  </div>
</header> -->

<header class="header header-private" >
  <div class="left-container">
    <button class="toggle-menu" [ngClass]="{open: sidebarVisible}" (click)="toggleSideber()">
      <span></span>
      <span></span>
      <span></span>
    </button>
    <a class="logo-link" routerLink="/home/all" [queryParams]="{ key: getRandomKey()}">
      <div class="logo"></div>
    </a>
  </div>
  <div class="right-container">
    <div class="buttons-container">
      <a routerLink="/adjudication" *ngIf="currentUser?.type === 'operator' || currentUser?.type ==='admin' || currentUser?.type === 'operator_high'">
        <i class="icon-write"></i>
      </a>
      <a routerLink="/digest">
        <i class="icon-new-digest"></i>
      </a>
    </div>
    <div class="profile-container">
      <div class="login-link" *ngIf="!isAuth">
        <a routerLink="/login">Увійти до акаунту</a>
      </div>
      <div *ngIf="isAuth" class="profile-toggle" [ngClass]="{active: profileStatus}" (click)="setProfileStatus(!profileStatus)">
        <span>{{getFullName(currentUser)}}</span>
        <i class="icon-arrow-sharp"></i>
      </div>
    </div>
    <app-header-profile
    [currentUser]="currentUser"
    [profileStatus]="profileStatus"
    [userName]="getFullName(currentUser)"
    (ToggleProfile)="setProfileStatus(!profileStatus)"
    >
  </app-header-profile>
  </div>


</header>
