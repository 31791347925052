<div class="list-item">
  <div class="list-inner">
    <div class="icon-container">
      <img src="../../../../assets/images/document.svg" alt="" />
    </div>
    <div class="content-container">
      <a class="link-item" routerLink="/home/search/{{data?.id}}">
        <h5>{{data?.title}}</h5>
      </a>
      <p>{{data?.text}}</p>
        <div *ngIf="search" class="index-wrapper">
          <div class="index-container">
            <i class="icon-search-index"></i>
            індекс: {{data?.v_index}}
            {{data.documents && data.documents[0]
              ? data.documents[0].v_index
              : ""}}
          </div>
        </div>
    </div>
      <div class="related-items">
       <ng-container *ngIf="data.documents?.length && data.documents[0].id">
        <div
        class="related-items-toggle"
        [ngClass]="{open:active}"
        (click)="setActive(!active)"
      >
        <i class="icon-attached"></i>
        <span>{{data.documents?.length}} пов’язаних рішень</span>
        <i class="icon-arrow"></i>
      </div>
        <div [ngClass]="{open: active}" class="related-list">
            <a
              *ngFor="let element of data.documents"
              class="related-link"
              routerLink="/adjudication/{{element.id}}"
            >
              <i class="icon-attached"></i>
              <span>{{element.title}}</span>
        </a>
        </div>
       </ng-container>
      </div>
  </div>
</div>
