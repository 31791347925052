import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService as AuthGuard } from './auth/helpers/auth-guard.service';
import { LoginGuardService } from './auth/helpers/login-guard.service';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [LoginGuardService]
  },
  {
    path: '',
    loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'adjudication',
    loadChildren: () => import('./modules/adjudication/adjudication.module').then(m => m.AdjudicationModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'new-court-decisions',
    loadChildren: () => import('./modules/new-court-decisions/new-court-decisions.module').then(m => m.NewCourtDecisionsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'decision-yespl',
    loadChildren: () => import('./modules/decision-yespl/decision-yespl.module').then(m => m.DecisionYesplModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'digest',
    loadChildren: () => import('./modules/digest/digest.module').then(m => m.DigestModule),
    // canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
