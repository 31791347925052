import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['./sidebar-item.component.scss']
})
export class SidebarItemComponent implements OnInit {

  constructor() { }
  @Input() route;
  @Input() isAuth = false;
  @Output() toggle = new EventEmitter();
  ngOnInit(): void {}

}
