<div class="auth-block" *ngIf="!request; else elseBlockAuth">
  <div class="title-container">
    <h2>Відновлення пароля</h2>
    <p>
      Введіть адресу своєї електронної пошти, і система надішле лист із Вашим ключем
    </p>
  </div>
  <div class="form-container" [formGroup]="restoreForm">
    <div class="inputs">
      <div class="input-container with-icon"
      [ngClass]="{
        error: (f('email').invalid && (f('email').dirty && f('email').touched)),
        'placeholder-disabled': f('email').value}">
        <input type="email" email #email formControlName="email">
        <div class="icons-container">
          <div class="icon">
            <i class="icon-email"></i>
          </div>
        </div>
        <div class="placeholder">Введіть email</div>
      </div>
    </div>
    <button class="button-submit" (click)="onSubmit()">
      Відновити пароль
      <i class="icon-back"></i>
      </button>
    <div class="links">
      <p></p>
      <a routerLink="/">Повернутися до входу</a>
    </div>
  </div>
</div>
<!-- success block -->
<ng-template #elseBlockAuth>
  <div class="auth-block authswitchfade-enter-done">
    <div class="title-container">
      <h2>Посилання надіслано на пошту!</h2>
      <p>Будь ласка, перевірте свою поштову скриньку й обов’язково продивіться папки «Вхідні» або «Спам»</p>
    </div>
    <div class="form-container">
      <button class="button-submit single-button" routerLink="/">
        Повернутися до входу
        <i class="icon-back"></i>
      </button>
    </div>
  </div>
</ng-template>
